import React, { useState, useEffect, useCallback } from 'react';
import styles from './Timer.module.scss';

export default function TimerDisplay({ isLive, onAirAt }) {
  const calculateDifference = useCallback(() => {
    if (!onAirAt) {
      return 0;
    }

    const currentDate = new Date();
    const currentDateUTC = new Date(currentDate.toISOString());
    const providedDate = new Date(onAirAt + 'Z');

    // Calculate difference in milliseconds
    const millisecondsDifference = currentDateUTC - providedDate;
    return millisecondsDifference > 0 ? parseInt(millisecondsDifference / 1000, 10) : 0
  }, [onAirAt])

  const [seconds, setSeconds] = useState(calculateDifference);

  useEffect(() => {
    if (onAirAt) {
      setSeconds(calculateDifference());
    }
  }, [onAirAt, calculateDifference]);

  useEffect(() => {
    let interval = null;

    if (isLive) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
      }, 1000);
    } else if (!isLive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isLive, seconds]);

  // Function to format time from seconds to HH:MM:SS
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    // Pad the numbers to ensure they're displayed as two digits
    const formattedTime = [
      hours.toString().padStart(2, '0'),
      minutes.toString().padStart(2, '0'),
      secs.toString().padStart(2, '0')
    ].join(':');

    return formattedTime;
  };

  return (
    <div style={{ color: isLive ? '#00FF4E' : '#FF8900' }} className={styles['timer']}>
      {formatTime(seconds)}
    </div>
  )
}