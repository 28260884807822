import React from 'react';
import { Login, Dashboard, Show, Caller, CallerDashboard, LiveShow } from './routes';
import { Navigate, Route, Routes } from 'react-router-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import LocalMediaProvider from './contexts/LocalMediaContext.js';
import StageProvider from './contexts/StageContext.js';
import { useAuth } from './contexts/AuthContext.js';

// Protected Route Component
const ProtectedRoute = ({ children, roles=[] }) => {
    const { user, token } = useAuth();

    if (!user?.guid || !token) {
        // User not authenticated, redirect to login page
        return <Navigate to="/login" />;
    } else if (roles.length && !roles.includes(user?.role)) {
        // User does not have the required roles, redirect to appropriate page
        switch (user?.role) {
            case 'host':
            case 'screener':
                return <Navigate to="/studio" />
            case 'caller':
                return <Navigate to="/caller" />
            default:
                return <Navigate to="/live" />
        }
    }

    return children;
};

const Wrapper = ({ children, roles }) => (
    <ProtectedRoute roles={roles}>
        <LocalMediaProvider>
            <StageProvider>
                {children}
            </StageProvider>
        </LocalMediaProvider>
    </ProtectedRoute>
);


const ALL_ROLES = ['host', 'screener', 'caller'];

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Navigate to="/login" />} />
                <Route path="/login" element={<Login />} />
                <Route path="/studio">
                    <Route index element={<ProtectedRoute roles={['host', 'screener']}><Dashboard /></ProtectedRoute>} />
                    <Route path=":show_guid" element={<Wrapper roles={['host', 'screener']}><Show /></Wrapper>} />
                </Route>
                <Route path="/live">
                    <Route index element={<LiveShow />} />
                </Route>
                <Route path="/caller">
                    <Route index element={<ProtectedRoute roles={ALL_ROLES}><CallerDashboard /></ProtectedRoute>} />
                    <Route path=":show_guid" element={<Wrapper roles={ALL_ROLES}><Caller /></Wrapper>} />
                </Route>
            </Routes>
        </Router>
    );
}

export default App;
