// React
import React, { useState, createContext, useContext, useMemo, useEffect } from 'react'
import { STAGE_TOKEN } from '../hooks/useStage';

const AuthContext = createContext()
export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
  const [state, setState] = useState({
    token: localStorage.getItem('token'),
    user: JSON.parse(localStorage.getItem('user') ?? '{}'),
  })

  useEffect(() => {
    if (state.token) {
      localStorage.setItem('token', state.token);
    } else {
      localStorage.removeItem('token');
    }
  }, [state.token])

  const value = useMemo(
    () => ({
      ...state,
      setToken: token => {
        setState({
          ...state,
          token,
        })
      },
      setUser: user => {
        localStorage.setItem('user', JSON.stringify(user))
        setState({
          ...state,
          user,
        })
      },
      onLogin: ({ user, token }) => {
        localStorage.setItem('user', JSON.stringify(user))
        setState({
          ...state,
          user,
          token,
        })
      },
      logout: (client) => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem(STAGE_TOKEN);

        client?.cache?.evict({ id: 'ROOT_QUERY', fieldName: 'me' });
        client?.cache?.gc(); // Garbage collect any unused data

        setState({
          token: null,
          user: {},
        })
      }
    }),
    [state, setState],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
