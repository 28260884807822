import React from 'react';
import styles from './DarkSelect.module.scss';
import { ReactComponent as DropdownIcon } from '../assets/dropdown-arrow.svg';

export default function DarkSelect({ title, options, onChange, value, icon }) {

    const handleChange = async (e) => {
        e.stopPropagation();
        await onChange(e.target.value);
    };

    return (
        <>
            <label className={styles['select-label']} htmlFor={title}>{title}</label>
            <div onClick={(e) => e.stopPropagation()} className={styles['select-wrapper']}>
                <div className={styles['select-icon']}>
                    {icon && icon}
                </div>
                <select
                    id={title}
                    className={styles['select']}
                    onChange={handleChange}
                    value={value}
                    style={{appearance: 'none', // Hides the default dropdown arrow
                            WebkitAppearance: 'none',
                            MozAppearance: 'none'}}>
                    {options.map((option) => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                </select>
                <DropdownIcon className={styles['dropdown-icon']} />
            </div>
        </>
    );
}
