import React from 'react';
import styles from './Button.module.scss';

export default function HeaderControlButton({ onClick, children }) {
    return (
        <button className={styles['headerControl']} onClick={onClick}>
          {children}
        </button>
    );
}
