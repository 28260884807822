import { useState, useEffect } from 'react'

const getLocalStorageValue = (key) => {
    const val = localStorage.getItem(key)
    if (!val) return null
    try {
        return JSON.parse(val)
    } catch (e) {
        return null
    }
}

const setLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value))
}

const removeLocalStorage = (key) => {
    localStorage.removeItem(key)
}

/**
* A testJSDoc comment should begin with a slash and 2 asterisks.
* Inline tags should be enclosed in braces like {@code this}.
* @desc Block tags should always start on their own line.
*/
export const usePersistentState = (key, defaultState = '') => {
    const [state, setState] = useState(getLocalStorageValue(key) || defaultState)

    const clearState = () => {
        setState(null)
    }

    useEffect(() => {
        if(state === null) {
            removeLocalStorage(key)
        } else {
            setLocalStorage(key, state)
        }

    })

    return [state, setState, clearState]
}