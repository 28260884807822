import React from 'react';
import background from '../assets/images/background.png';

export default function Background({ children, position='center' }) {
    const style = {
      flex: 1,
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      backgroundPosition: position,
    }

    return (
        <div style={style}>
            {children}
        </div>
    );
}
