import React from 'react'
import { useLocation } from 'react-router-dom'
import styles from "./Sidebar.module.scss"
import { Link } from "react-router-dom"

const Item = ({ to, label }) => {
    const location = useLocation()
    const active = location.pathname === to
    return (
        <Link className={active ? styles['sidebar__link__active'] : null} to={to}>
            {label}
        </Link>
    )
}

const Sidebar = ({children}) => {
    return (
        <nav className={styles['sidebar']} aria-label="Main Nav">
            <ul className={styles['sidebar__links']}>
                {React.Children.map(children, child => {
                    return <li className={styles['sidebar__link']}>{child}</li>
                })}
            </ul>
        </nav>
    )
}
Sidebar.Item = Item
export { Sidebar }