import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Select from './Select';
import ProfileImg from './ProfileImg';
import { ReactComponent as MicSquare } from '../assets/mic-subtract-square.svg';
import { ReactComponent as BackArrow } from '../assets/back-circle.svg';
import { ReactComponent as Dots } from '../assets/dots-circle.svg';
import styles from './ScreeningDetails.module.scss';
import { SEND_TO_HOST, STOP_TALKING } from '../util/graphql.js';
import { useStudio } from '../routes/Show.js';

const STATES = [
  { value: '', label: 'State', disabled: true },
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
]

export default function ScreeningDetails({ caller, setCaller }) {
  const { leaveStage, stageJoined } = useStudio();

  const [name, setName] = useState(caller?.name)
  const [reason, setReason] = useState(caller?.reason ?? '');
  const [city, setCity] = useState(caller?.location?.split(', ')?.[0] ?? '');
  const [state, setState] = useState(caller?.location?.split(', ')?.[1] ?? '');

  const { show_guid } = useParams();

  const [sendToHost, sendToHostData] = useMutation(SEND_TO_HOST, { refetchQueries: ['getShow'] });
  const [stopTalking] = useMutation(STOP_TALKING);

  const clearCaller = async () => {
    setCaller()
    if (stageJoined) {
      await leaveStage()
    }
  }

  const goBack = async () => {
    let resp = await stopTalking({ variables: { showGuid: show_guid, uid: caller?.guid } })
    if (resp.data?.stopTalking?.result) {
      await clearCaller()
    }
  };

  const onSubmit = async () => {
    let resp = await sendToHost({
      variables: {
        showGuid: String(show_guid),
        uid: String(caller?.guid),
        reason: reason,
        location: `${city}${city ? ', ' : ''}${state}`,
        name: name,
      }
    })
    if (resp.data?.sendToHost?.result) {
      clearCaller()
    }
  }

  const spinner = (
    <i style={{ color: 'rgba(51, 66, 80, 0.75)', fontSize: 20 }} className="fa fa-circle-o-notch fa-spin" />
  )

  return (
    <div className={styles['container']}>
      <div className={styles['container__header']}>
        <div className={styles['container__header__nav']}>
          <BackArrow style={{ cursor: 'pointer' }} onClick={goBack} />
          <ProfileImg user={caller} size={58} />
          <Dots />
        </div>
        <div className={styles['container__header__title']}>{caller?.name}</div>
      </div>
      <div className={styles['section']}>
        <div className={styles['section__header']}>
          <MicSquare fill="#FF8900" />
          <div className={styles['section__header__title']}>WANTS TO TALK ABOUT?</div>
        </div>
        <div className={styles['section__content']}>
          {!caller?.name && (
            <div style={{ height: caller?.name ? 0 : 118, transition: 'height 0.5s', overflow: 'hidden' }}>
              <label className={styles['name']}>Name</label>
              <input value={name} onChange={(e) => setName(e.target.value)} />
              <label className={styles['name']}>Reason</label>
            </div>
          )}
          <textarea className={styles['textarea']} multiline={true} value={reason} onChange={(e) => setReason(e.target.value)} />
          <label className={styles['location']}>Location</label>
          <div className={styles['location__form']}>
            <input placeholder="City" value={city} onChange={(e) => setCity(e.target.value)} />
            <div style={{ width: 20 }} />
            <Select options={STATES} value={state} onChange={setState} />
            <div style={{ flex: 1 }} />
          </div>
          <div className={styles['footer']}>
            <div style={{ flex: 1 }} />
            <button onClick={onSubmit} className={styles['footer__submit']}>{sendToHostData.loading ? spinner : 'Send to Host'}</button>
          </div>
        </div>
      </div>
    </div>
  );
}
