// React
import { useMemo } from "react";
import {
  InMemoryCache,
  ApolloProvider as ApolloProviderReact,
  ApolloClient,
  ApolloLink,
  createHttpLink,
  from,
  // split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { useAuth } from "../contexts/AuthContext.js";

// Context

export default function ApolloProvider({ children }) {
  const { token, logout } = useAuth();

  const httpLink = useMemo(() => {
    let environ = process.env.REACT_APP_ENVIRON;
    let uri =
      environ !== "prod"
        ? `https://api-${environ}.audioroad.net/gql`
        : "https://api.audioroad.net/gql";

    return createHttpLink({
      uri: uri,
    });
  }, []);

  const errorLink = useMemo(
    () =>
      onError(({ operation, response, graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          // console.log('ERROR:', operation.operationName, graphQLErrors)
          // graphQLErrors.map(({ message, locations, path }) =>
          //   console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
          // )
          // if (isBetaGraphQL) {
          //   // toast.show(`Disabling GraphQL Beta due to errors in ${operation.operationName}`, {
          //   //   type: 'custom_type',
          //   //   data: { type: 'error' },
          //   // })
          //   setIsBetaGraphQL(false)
          // }
        }

        if (networkError?.statusCode === 401) {
          console.log(operation.operationName, "- 401 Unauthorized");
          logout();
        } else if (networkError) {
          console.log(
            `${operation.operationName} - [Network error]: ${networkError}`
          );
        }

        if (response) {
          console.log(response.errors);
          // response.errors = null
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Refresh token if it is expired
  const checkToken = useMemo(() => {
    return new ApolloLink((operation, forward) => {
      return forward(operation);
      // if (token && !tokenIsValid(token)) {
      //   if (!isRefreshingToken.current) {
      //     // Trigger token refresh.
      //     refreshToken().then().catch(console.warn)
      //   }
      // } else {
      //   return forward(operation)
      // }
    });
  }, []);

  const createAuthLink = () =>
    setContext(({ headers }) => {
      return {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          ...headers,
        },
      };
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const authLink = useMemo(() => createAuthLink(), [token]);

  const client = useMemo(
    () =>
      new ApolloClient({
        link: from([checkToken, errorLink, authLink.concat(httpLink)]),
        cache: new InMemoryCache(),
      }),
    [checkToken, errorLink, authLink, httpLink]
  );

  return <ApolloProviderReact client={client}>{children}</ApolloProviderReact>;
}
