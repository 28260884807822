import React, { useContext } from 'react';
import { LocalMediaContext } from '../contexts/LocalMediaContext.js';
import HeaderControlButton from './HeaderControlButton.js';
import { ReactComponent as MicActive } from '../assets/mic-active.svg';
import { ReactComponent as MicInactive } from '../assets/mic-inactive.svg';
import { ReactComponent as CameraActive } from '../assets/camera-active.svg';
import { ReactComponent as CameraInactive } from '../assets/camera-inactive.svg';
import LocalVideo from '../components/LocalVideo.js';

export default function MediaControls({ showVideoFeed, audioOnly }) {
    const { audioMuted, videoMuted, toggleDeviceMute, localAudioStream, localVideoStream } = useContext(LocalMediaContext);

    const videoElement = videoMuted || showVideoFeed ? (
        <HeaderControlButton onClick={() => toggleDeviceMute(localVideoStream)}>
            {videoMuted ? <CameraInactive height={30} fill="white" /> : <CameraActive height={30} fill="#00ff4e" />}
        </HeaderControlButton>
    ) : (
        <div onClick={() => toggleDeviceMute(localVideoStream)} style={{
            backgroundColor: 'black',
            width: 84,
            height: 50,
            borderRadius: 10,
            border: '2px solid rgba(255, 255, 255, 0.6)',
            overflow: 'hidden',
            cursor: 'pointer',
        }}>
            <LocalVideo />
        </div>
    )
    return (
        <div style={{ display: 'flex', width: '180px', justifyContent: 'space-between' }}>
            <HeaderControlButton onClick={() => toggleDeviceMute(localAudioStream)}>
                {audioMuted ? <MicInactive height={30} width={25} fill="white" /> : <MicActive height={30} width={25} fill="#00ff4e" />}
            </HeaderControlButton>
            {!audioOnly && videoElement}
        </div>
    );
}
