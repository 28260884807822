import React, { useContext } from 'react';
import { LocalMediaContext } from '../contexts/LocalMediaContext.js';
import { ReactComponent as MicActive } from '../assets/mic-active.svg';
import { ReactComponent as MicInactive } from '../assets/mic-inactive.svg';
import { ReactComponent as CameraActive } from '../assets/camera-active.svg';
import { ReactComponent as CameraInactive } from '../assets/camera-inactive.svg';

export default function MediaControls({ audioOnly }) {
    const { audioMuted, videoMuted, toggleDeviceMute, localAudioStream, localVideoStream } = useContext(LocalMediaContext);

    const buttonStyle = {
      backgroundColor: 'transparent',
      border: 'none',
      padding: 0,
      margin: 0,
      lineHeight: 1,
      flex: 1,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    }

    return (
        <div style={{ display: 'flex', width: 180, justifyContent: 'space-between', backgroundColor: 'rgba(255, 255, 255, 0.15)', height: 75, borderRadius: 36.5, padding: '0 10px' }}>
            <button style={buttonStyle} onClick={() => toggleDeviceMute(localVideoStream)}>
                {videoMuted ? <CameraInactive height={30} fill="red" /> : <CameraActive height={30} fill="#00ff4e" />}
            </button>
            <div style={{ width: 2, height: '100%', backgroundColor: 'black' }} />
            <button style={buttonStyle} onClick={() => toggleDeviceMute(localAudioStream)}>
                {audioMuted ? <MicInactive height={30} width={25} fill="red" /> : <MicActive height={30} width={25} fill="#00ff4e" />}
            </button>
        </div>
    );
}
