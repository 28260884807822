import React, { useContext } from 'react';
import { LocalMediaContext } from '../contexts/LocalMediaContext.js';
import Video from './Video.js';

export default function LocalVideo(props) {
    const { localVideoStream } = useContext(LocalMediaContext);
    return (
        <Video stageStream={localVideoStream} mirrored={true} {...props} />
    );
}
