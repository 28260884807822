import React, { useState, useContext, useRef, useEffect, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import WebSocket from '../components/WebSocket.js';
import Background from '../components/Background.js';
import CallerMediaControls from '../components/CallerMediaControls.js';
import { StageContext } from '../contexts/StageContext.js';
import { LocalMediaContext } from '../contexts/LocalMediaContext.js';
import { GET_SHOW_CALLER, START_CALL, STOP_CALL } from '../util/graphql.js';
import VideoSection from './Caller/VideoSection.js';
import { CallerFooter } from './Caller';
import { STAGE_TOKEN } from '../hooks/useStage.js';

const xIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="27.671" height="27.669" viewBox="0 0 27.671 27.669">
    <g id="Group_1583" data-name="Group 1583" transform="translate(-179.121 -696.121)">
      <path id="Path_1312" data-name="Path 1312" d="M0,0V29.13" transform="translate(203.256 720.255) rotate(135)" fill="none" stroke="#00b1ff" strokeLinecap="round" strokeWidth="5"/>
      <line id="Line_81" data-name="Line 81" y2="29.13" transform="translate(182.656 720.255) rotate(-135)" fill="none" stroke="#00b1ff" strokeLinecap="round" strokeWidth="5"/>
    </g>
  </svg>
)

const backIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="15.142" height="25.623" viewBox="0 0 15.142 25.623">
    <path id="Union_129" data-name="Union 129" d="M11.162,14.458.683,3.979a2.332,2.332,0,0,1,3.3-3.3l8.83,8.831L21.642.68a2.332,2.332,0,0,1,3.3,3.3L14.462,14.458a2.32,2.32,0,0,1-1.094.616,2.34,2.34,0,0,1-2.206-.615Z" transform="translate(15.14 -0.001) rotate(90)" fill="#00b1ff"/>
  </svg>
)

const MediaSettingsButton = ({ onClick }) => {
  return (
    <div onClick={onClick} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', margin: '30px auto' }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="23.967" height="20" viewBox="0 0 23.967 20">
        <g id="Group_1507" data-name="Group 1507" transform="translate(-175 -740)" opacity="0.249">
          <path id="volume-off" d="M154.257,112.613a1.216,1.216,0,0,1-.729-.244l-.034-.026-4.69-3.891h-3.573A1.239,1.239,0,0,1,144,107.2v-5.81a1.239,1.239,0,0,1,1.231-1.247H148.8l4.69-3.891.034-.026a1.218,1.218,0,0,1,1.285-.109,1.249,1.249,0,0,1,.675,1.113v14.132A1.239,1.239,0,0,1,154.257,112.613Z" transform="translate(31 645.852)" fill="#fff"/>
          <path id="bluetooth" d="M135.081,33.817a.882.882,0,0,0-.254-.654l-4.859-4.912a.861.861,0,0,0-.946-.191.877.877,0,0,0-.536.811v7.216l-3.428-2.97a.861.861,0,0,0-1.206.111.884.884,0,0,0,.078,1.222l4.09,3.544-4.09,3.544a.881.881,0,0,0,.265,1.511.861.861,0,0,0,.863-.178l3.428-2.97v7.216a.877.877,0,0,0,.536.81.861.861,0,0,0,.946-.19l4.859-4.912a.884.884,0,0,0-.049-1.286l-4.09-3.544,4.09-3.544a.881.881,0,0,0,.3-.632Zm-2.144,8.437L130.221,45V39.9Zm-2.715-6.167v-5.1l2.716,2.745Z" transform="translate(63.886 712.006)" fill="#fff"/>
        </g>
      </svg>
      <div style={{ marginLeft: 5, textAlign: 'center', fontSize: 19, fontWeight: '500', color: 'rgba(255, 255, 255, 0.25)' }}> Settings</div>
    </div>
  )
}

const NavButton = ({ children, ...props }) => (
  <button style={{ padding: 0, margin: 0, width: 75, height: 75, alignItems: 'center', justifyContent: 'center', display: 'flex', backgroundColor: 'rgba(255, 255, 255, 0.15)', borderRadius: 60, border: 0, fontSize: 30, fontWeight: '900', color: '#00B1FF' }} {...props}>
    {children}
  </button>
)

export const CALLER_STATE = {
  closed: { state: 'closed', message: 'Calls are closed' },
  preview: { state: 'preview' },
  waitingOnScreener: { state: 'waitingOnScreener', message: <span>We will<br />be right<br />with you</span>, },
  screening: { state: 'screening', message: <span>What would<br />you like to<br />talk about?</span> },
  approved: { state: 'approved', message: <span>You're in<br />line to talk<br />with Kevin</span> },
  goingLive: { stage: 'goingLive', message: 'Going Live' },
  live: { state: 'live', message: 'You\'re On Air' },
  needsScreenerHelp: { state: 'needsScreenerHelp', message: 'I Need Help' },
  muted: { state: 'muted', message: 'You\'re Muted' },
  end: { state: 'end', message: 'Thanks for your call' },
}

const CallerContent = ({ data, showVideo }) => {
  const navigate = useNavigate();
  const { setAudioMute, setVideoMute, localVideoStream, localAudioStream } = useContext(LocalMediaContext);
  const { switchStage, ...stage } = useContext(StageContext);
  const [callerState, setCallerState] = useState(data?.show?.allowCallers ? showVideo ? CALLER_STATE.preview : CALLER_STATE.waitingOnScreener : CALLER_STATE.closed)
  const [errorMessage, setErrorMessage] = useState("");
  const [settingsVisible, setSettingsVisible] = useState(false);
  const [countdown, setCountdown] = useState(3);
  const { show_guid } = useParams();
  const [startCall, startCallData] = useMutation(START_CALL);
  const [stopCall, stopCallData] = useMutation(STOP_CALL);
  const settingsRef = useRef(null);

  const handleClickOutside = (event) => {
    if (settingsRef.current && !settingsRef.current.contains(event.target)) {
      setSettingsVisible(false);
    }
  };

  const goBack = useCallback(() => {
    window.ReactNativeWebView?.postMessage('goBack');
    navigate(-1);
  }, [navigate]);

  const pauseStream = () => {
    window.ReactNativeWebView?.postMessage('pauseStream');
  }

  const restartStream = () => {
    window.ReactNativeWebView?.postMessage('restartStream');
  }

  useEffect(() => {
    callerState.state !== CALLER_STATE.waitingOnScreener.state && localStorage.setItem('callerState', callerState.state);
    let timeout
    if (callerState.state === CALLER_STATE.end.state) {
      timeout = setTimeout(() => {
        goBack();
      }, 5000);
    }

    let intervalId
    const runTimer = () => {
      intervalId = setInterval(() => {
        setCountdown(currentCount => {
          if (currentCount > 1) {
            // Decrease the count by 1
            return currentCount - 1;
          } else {
            // Clear interval if count is 0 or less
            setCallerState(CALLER_STATE.live);
            setCountdown(3);
            clearInterval(intervalId);
            return 0;
          }
        });
      }, 1000);
    }

    if (callerState.state === CALLER_STATE.goingLive.state) {
      runTimer();
    }

    return () => {
      clearTimeout(timeout);
      clearInterval(intervalId);
    };
  }, [callerState, goBack]);

  useEffect(() => {
    stage.setAudioOnly(!showVideo)
  }, [showVideo, stage]);

  useEffect(() => {
    if (stage.stageJoined) {
      setSettingsVisible(false);
    }
  }, [stage.stageJoined]);

  useEffect(() => {
    if (settingsVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [settingsVisible]);

const joinCall = useCallback(async () => {
  try {
    await startCall({ variables: { showGuid: show_guid } }).then((resp) => {
      if (resp.data?.startCall?.result) {
        setErrorMessage()
        setCallerState(CALLER_STATE.waitingOnScreener)
      } else {
        setErrorMessage("Could not join call")
      }
    })
  } catch (e) {
    setErrorMessage("There was an error");
    console.log(e)
  }
}, [startCall, setErrorMessage, setCallerState, show_guid]);

  const endCall = async () => {
    try {
      await stopCall({ variables: { showGuid: show_guid } })
      await switchStage();
      setCallerState(CALLER_STATE.end)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    const stageToken = localStorage.getItem(STAGE_TOKEN);
    const savedCallerState = localStorage.getItem('callerState');
    if (stageToken && !stage.stageJoined) {
      setTimeout(() => {
        console.info('rejoining stage', CALLER_STATE[savedCallerState])
        stage.joinStage(stageToken);
        savedCallerState && setCallerState(CALLER_STATE[savedCallerState]);
      }, 2000)
    } else if (data?.show?.audioOnly && !stage.stageJoined && data?.show?.allowCallers) {
      joinCall()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const wsListener = async (cmd) => {
    try {

      switch (cmd.action) {
        case "call.talk":
          // console.log("WS start talking");
          if (cmd.detail.stage === "pre") {
            setCallerState(CALLER_STATE.screening);
          }
          else {
            setCallerState(CALLER_STATE.goingLive);
          }
          await switchStage(cmd.detail.token);
          pauseStream();
          break;

        case "call.approved":
          // console.log("WS approved for show");
          setCallerState(CALLER_STATE.approved);
          await switchStage(cmd.detail.token);
          pauseStream();
          break;

        case "call.rescreen":
          // console.log("WS go back to screener");
          setCallerState(CALLER_STATE.waitingOnScreener);
          await switchStage(cmd.detail.token);
          restartStream();
          break;

        case "call.end":
          // console.log("WS end call");
          setCallerState(CALLER_STATE.end);
          await switchStage();
          restartStream();
          break;

        case "show.stop":
          // console.log("WS show stop");
          setCallerState(CALLER_STATE.end);
          await switchStage();
          restartStream();
          break;

        case "call.silence":
          if (cmd.detail.stage === "pre") {
            // console.log('WS leave pre stage');
            setCallerState(CALLER_STATE.waitingOnScreener);
            await switchStage();
          } else {
            // console.log('WS Live. silence. listen only')
            await switchStage(cmd.detail.token);
          }
          break;

        case "call.mute":
          // console.log("WS MUTE", cmd.detail);
          if (cmd.detail.mute_audio) {
            setCallerState(CALLER_STATE.muted);
          } else if (cmd.detail.stage === 'live' && !cmd.detail.mute_audio) {
            setCallerState(CALLER_STATE.live);
          } else if (cmd.detail.stage === 'pre' && !cmd.detail.mute_audio) {
            setCallerState(CALLER_STATE.screening)
          }

          typeof cmd.detail?.mute_audio === 'boolean' && setAudioMute(cmd.detail.mute_audio)
          typeof cmd.detail?.mute_video === 'boolean' && setVideoMute(cmd.detail.mute_video)

          break;

        case "show.start":
          setCallerState(CALLER_STATE.preview);
          break;

        default:
          break;
      }
    } catch (e) {
      console.log("WS PROCESS FAIL");
      console.log(e);
    }
  }

  // NOTE: don't use state values in this cached function
  const onJsonRef = useRef(wsListener);

  useEffect(() => {
    onJsonRef.current = wsListener;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localVideoStream, localAudioStream])

  const toggleSettings = () => {
    setSettingsVisible(!settingsVisible);
  };

  const spinner = (
    <i style={{ color: 'rgba(255, 255, 255, 0.75)', fontSize: 20 }} className="fa fa-circle-o-notch fa-spin" />
  )

  return (
    <>
      <WebSocket showGuid={show_guid} onCommand={onJsonRef.current} />
      <div style={{ display: 'flex', flexDirection: 'column', position: 'relative', justifyContent: 'space-between', height: '100vh' }}>
        <div style={{ alignItems: 'center', paddingTop: 50, textAlign: 'center' }}>
          {/* <button onClick={() => setShowVideo(!showVideo)}>Toggle Video - {showVideo ? 'true' : 'false'}</button> */}
          <p style={{ fontSize: 22, fontWeight: '900', fontFamily: 'Roboto', opacity: 0.75, color: 'white' }}>{data?.show?.title ?? ' '}</p>
        </div>
        {errorMessage && (
          <div style={{ margin: '0 auto 20px auto', textAlign: 'center', }}>
            <h4
              onClick={() => setErrorMessage()}
              style={{
                display: 'inline',
                backgroundColor: 'rgba(255, 255, 255, 0.75)',
                color: 'red',
                cursor: 'pointer',
                padding: '0.25em 0.5em',
                borderRadius: '8px',
              }}
            >
              {errorMessage}
            </h4>
          </div>
        )}
        {callerState.state === CALLER_STATE.goingLive.state ? (
          <div style={{ margin: 'auto' }}>
            <div style={{ fontSize: 48, fontWeight: '900', color: 'white', textAlign: 'center', textTransform: 'uppercase', lineHeight: 1 }}>
              {callerState.message}
            </div>
            <div style={{ fontSize: 150, fontWeight: '900', color: '#00FF4E', textAlign: 'center' }}>
              {countdown}
            </div>
            <div style={{ position: 'absolute', opacity: 0, bottom: 0, left: 0, height: 0, width: 0, overflow: 'hidden' }}>
              <VideoSection callerState={callerState} participants={stage.participants} audioOnly={!showVideo} stageJoined={stage.stageJoined} />
            </div>
          </div>
        ) : (
          <div style={{ flex: 1, flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 40, transition: 'height 0.5s ease-in-out' }}>
            <VideoSection callerState={callerState} participants={stage.participants} audioOnly={!showVideo} stageJoined={stage.stageJoined} />
          </div>
        )}
        <div style={{ maxWidth: 400, margin: '5px auto', width: '100%', padding: `0 40px` }}>
          <div style={{ display: 'flex', justifyContent: [CALLER_STATE.end.state, CALLER_STATE.closed.state].includes(callerState.state) || !showVideo ? 'center' : 'space-between', alignItems: 'center' }}>
            {callerState.state === CALLER_STATE.preview.state ? (
              <NavButton onClick={() => goBack()}>{backIcon}</NavButton>
            ) : (
              <NavButton onClick={() => [CALLER_STATE.end.state, CALLER_STATE.closed.state].includes(callerState.state) ? goBack() : endCall()}>{stopCallData.loading ? spinner : xIcon}</NavButton>
            )}
            {callerState.state === CALLER_STATE.preview.state ? (
              <button onClick={() => joinCall()} style={{ margin: 0, width: 168, height: 75, backgroundColor: 'rgba(255, 255, 255, 0.15)', borderRadius: 60, border: 0, fontSize: 30, fontWeight: '900', color: '#00B1FF' }}>{startCallData.loading ? spinner : 'NEXT'}</button>
            ) : (
              ![CALLER_STATE.end.state, CALLER_STATE.closed.state].includes(callerState.state) && showVideo && <CallerMediaControls audioOnly={!showVideo} />
            )}
          </div>
          {!stage.stageJoined ? (
            <MediaSettingsButton onClick={() => toggleSettings()} />
          ) : (
            <div style={{ height: 90 }} />
          )}
        </div>
      </div>
      <CallerFooter ref={settingsRef} show={settingsVisible} close={() => setSettingsVisible(false)} audioOnly={!showVideo} />
    </>
  );
}

export default function Caller() {
  const { show_guid } = useParams();
  const { data, loading } = useQuery(GET_SHOW_CALLER, { variables: { guid: show_guid }, fetchPolicy: 'network-only' });
  const { initializeMedia } = useContext(LocalMediaContext);
  const spinner = (
    <i style={{ color: 'rgba(255, 255, 255, 0.75)', fontSize: 60 }} className="fa fa-circle-o-notch fa-spin" />
  )
  const audioOnly = useMemo(() => data?.show?.audioOnly ?? null, [data]);

  useEffect(() => {
    if (audioOnly !== null) {
      initializeMedia(!audioOnly, true);
    }
  }, [audioOnly])

  return (
    <Background>
      {loading ? (
        <div style={{ flex: 1, justifyContent: 'center', height: '100%',alignItems: 'center', display: 'flex' }}>
          {spinner}
        </div>
      ) : (
        <CallerContent data={data} showVideo={!(audioOnly ?? true)} />
      )}
    </Background>
  );
}
