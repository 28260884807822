import React, { useContext } from 'react';
import LocalVideo from '../../components/LocalVideo.js';
import { useAuth } from '../../contexts/AuthContext.js';
import Participant from '../../components/Participant.js';
import styles from './VideoSection.module.scss';
import { LocalMediaContext } from '../../contexts/LocalMediaContext.js';
import { CALLER_STATE } from '../CallerScreen.js';
import LargeMute from '../../components/LargeMute.js';

export default function VideoSection({ callerState, participants, audioOnly=true, stageJoined }) {
  const { audioMuted } = useContext(LocalMediaContext);
  const { user } = useAuth();

  const host = [...participants.values()]?.find(p => p.userId.includes('host')) ?? [...participants.values()]?.[0];
  const filtered = [...participants.values()]?.filter(value => value.userId !== `caller-${user.guid}` && value.userId !== host?.userId)

  const items = [...participants.values()].filter(value => value.userId !== `caller-${user.guid}`);
  const size = items.length

  const getTemplateColumns = () => {
    switch (size) {
      case 2:
        return '1fr 1fr';
      case 1:
        return '1fr 2fr';
      default:
        return '1fr 2fr';
    }
  }

  const getTemplateRows = () => {
    switch (size) {
      default:
        return '2fr 1fr';
    }
  }

  const gridContainerStyle = {
    maxHeight: callerState.state === CALLER_STATE.end.state ? 0 : 'auto',
    padding: size <= 1 ? '0 40px' : 0,
    gridTemplateColumns: getTemplateColumns(),
    gridTemplateRows: getTemplateRows(),
    transition: 'grid-template-columns 0.5 ease-in-out, grid-template-rows 0.5 ease-in-out, max-height 0.5s ease-in-out',
  }

  const getLocalVideoStyle = () => {
    switch (size) {
      case 0:
        return 'local';
      case 1:
        return 'single';
      default:
        return 'many';
    }
  }

  return (
    <>
      <div style={{
        fontSize: size > 1 ? 45 : 48,
        color: 'white',
        maxWidth: 350,
        maxHeight: callerState.message ? 184 : 0,
        fontWeight: '900',
        textTransform: 'uppercase',
        lineHeight: 1,
        textAlign: 'center',
        // margin: 'auto',
        // marginBottom: audioOnly ? 40 : 'auto',
        paddingBottom: callerState.message ? 40 : 0,
        display: 'flex',
        flex: 1,
        flexDirection: size > 1 ? 'row' : 'column',
        alignItems: 'center',
        transition: 'height 0.5s ease-in-out, min-height 0.5s ease-in-out, opacity 0.5s ease-in-out, padding-bottom 0.5s ease-in-out',
      }}>
        {[CALLER_STATE.live.state, CALLER_STATE.muted.state].includes(callerState.state) ? (
          <>
            YOU'RE
            <div style={{ paddingLeft: size > 1 ? 10 : 0, color: audioMuted ? 'red' : '#00FF4E', fontSize: size > 1 ? 45 : 75 }}>{audioMuted ? 'MUTED' : 'ON AIR'}</div>
          </>
        ) : (
          callerState.message
        )}
      </div>
      {audioOnly ? (
        <div style={{ height: 100 }}>
          {[CALLER_STATE.live.state, CALLER_STATE.muted.state, CALLER_STATE.screening.state].includes(callerState.state) ? <LargeMute /> : null}
          {items?.map(participant => (
            <div key={participant.id} style={{ position: 'absolute', height: 0, width: 0, opacity: 0 }}>
              <Participant {...participant} />)
            </div>
          ))}
        </div>
      ) : (
        <div className={styles['grid-container']} style={gridContainerStyle}>
          {host && (
            <div className={styles[`host-video${size > 1 ? '-many' : ''}`]}>
              <Participant {...host} />
            </div>
          )}
          <div className={styles[`local-video-${getLocalVideoStyle()}`]}>
            <div className={styles[`video-wrapper-${getLocalVideoStyle()}`]}>
              <LocalVideo />
            </div>
          </div>
          <div className={`${styles['guest-video']} ${styles['extra-guests']}`}>
            {filtered?.map(guest => (
              <div key={guest.id} className={styles['extra-guest']} style={{ maxWidth: size > 2 ? '50%' : '100%' }}>
                <Participant {...guest} />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}
