import React, { useEffect, useRef, useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import styles from './CallerControls.module.scss';
import { ReactComponent as XIcon } from '../assets/x.svg';
import { ReactComponent as MicAdd } from '../assets/mic-add.svg';
import { ReactComponent as MicActive } from '../assets/mic-active.svg';
import { ReactComponent as MicInactive } from '../assets/mic-inactive.svg';
import { ReactComponent as ArrowPerson } from '../assets/arrow-person.svg';
import { ReactComponent as CameraActive } from '../assets/camera-active.svg';
import { ReactComponent as CameraInactive } from '../assets/camera-inactive.svg';
import { ReactComponent as MapPin } from '../assets/map-pin.svg';
import { ReactComponent as ChatBubbles } from '../assets/chat-bubbles.svg';
import ProfileImg from './ProfileImg.js';
import { StreamType } from 'amazon-ivs-web-broadcast';
import Video from './Video.js';
import { SEND_TO_SCREENER, STOP_CALL, START_TALKING, STOP_TALKING, MUTE } from '../util/graphql.js';
import { ButtonGroup, ButtonIcon } from './ButtonGroup.js';
import { useStudio } from '../routes/Show.js';
import { useAuth } from '../contexts/AuthContext.js';

export default function CallerControlsLive({ caller }) {
  const { user } = useAuth();
  const { showGuid, setPreviewCaller, participants } = useStudio();
  const [muteLoading, setMuteLoading] = useState(false);
  const [muteVideoLoading, setMuteVideoLoading] = useState(false);

  const isHost = user?.role === 'host' ?? false;

  const liveParticipant = useMemo(() => {
    return [...participants?.values()].find(participant => {
      // console.log('PARTICIPANT', participant)
      let [t, u] = participant.userId?.split('-');
      // console.log('participant.id split (', t, ':', u, ') data caller guid', caller.guid)
      return t === 'caller' && u === caller.guid && caller.stage === 'live'
    })
  }, [participants, caller]);

  useEffect(() => {
    setMuteLoading(false);
  }, [liveParticipant?.audioMuted])

  useEffect(() => {
    setMuteVideoLoading(false);
  }, [liveParticipant?.videoStopped])

  const videoStream = liveParticipant?.streams?.find((stream) => stream.streamType === StreamType.VIDEO);
  const audioStream = liveParticipant?.streams?.find((stream) => stream.streamType === StreamType.AUDIO);
  const videoStopped = liveParticipant?.videoStopped ?? !videoStream
  const audioMuted = liveParticipant?.audioMuted ?? !audioStream
  const live = caller.talking

  const audioRef = useRef(undefined);

  useEffect(() => {
      if (audioRef.current && audioStream) {
          audioRef.current.srcObject = new MediaStream([audioStream.mediaStreamTrack]);
      }
  }, [audioRef, audioStream]);

  const isVideo = !!videoStream;
  const refetchQueries = ['getShow'];
  const [sendToScreener, sendToScreenerData] = useMutation(SEND_TO_SCREENER, { refetchQueries });
  const [stopCall, stopCallData] = useMutation(STOP_CALL, { refetchQueries });
  const [startTalking, startTalkingData] = useMutation(START_TALKING, { refetchQueries });
  const [stopTalking, stopTalkingData] = useMutation(STOP_TALKING, { refetchQueries });
  const [toggleMuteVideo, muteVideoData] = useMutation(MUTE, { onError: () => setMuteVideoLoading(false) });
  const [toggleMuteAudio, muteAudioData] = useMutation(MUTE, { onError: () => setMuteLoading(false) });

  const variables = { variables: { showGuid, uid: caller.guid }};

  const spinner = (
      <i style={{ color: 'rgba(51, 66, 80, 0.75)', fontSize: 20 }} className="fa fa-circle-o-notch fa-spin" />
  )

  const toggleTalking = useCallback(async (uid, showGuid) => {
    if (caller?.talking) {
      console.log('stop talking')
      await stopTalking({
        variables: {
          showGuid: String(showGuid),
          uid: String(uid),
        }
      }).catch(e => console.log('stopTalking error:', e))
    } else {
      console.log('start talking')
      await startTalking({
        variables: {
          showGuid: String(showGuid),
          uid: String(uid),
        }
      }).catch(e => console.log('startTalkingError:', e));
    }
  }, [caller, startTalking, stopTalking]);

  async function loadingTimeout(setLoading) {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }

  function onClickVideoToggle() {
    loadingTimeout(setMuteVideoLoading);
    !muteVideoData.loading && toggleMuteVideo({
      variables: {
        guid: showGuid,
        uid: caller.guid,
        video: !videoStopped,
      }
    })
  }

  function onClickAudioToggle() {
    loadingTimeout(setMuteLoading);
    !muteAudioData.loading && toggleMuteAudio({
      variables: {
        guid: showGuid,
        uid: caller.guid,
        audio: !audioMuted,
      }
    })
  }

  const videoToggleIcon = muteVideoLoading
    ? spinner
    : videoStopped
      ? <CameraInactive width={33} fill="red" />
      : <CameraActive width={33} height={20} fill="#1ECA3E" />

  const audioToggleIcon = muteLoading
    ? spinner
    : audioMuted
      ? <MicInactive width={31} height={25} fill="red" />
      : <MicActive width={31} height={25} fill="#1ECA3E" />

  return (
    <div className={styles['child']} onClick={() => setPreviewCaller(caller)}>
      {!isVideo ? (
        <ProfileImg user={caller} size={72} />
      ) : (
        <div className={styles['child__video']}>
          <Video stageStream={videoStream} />
        </div>
      )}
      <audio ref={audioRef} autoPlay />
      <div className={styles['child__content']} style={{ flexDirection: isVideo ? 'column' : 'row', alignItems: isVideo ? 'flex-start' : 'center' }}>
        <div style={{ flex: 1, paddingLeft: 20, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <div style={{ margin: 0, fontSize: 25, fontWeight: '500', color: 'black', lineHeight: 1.4 }}>{ caller.name ?? 'New Caller' }</div>
          <div>
            {caller.location && (
              <div className={styles['child__content__details']}>
                <MapPin style={{ marginRight: 5 }} />
                {caller.location}
              </div>
            )}
            {caller.reason && (
              <div className={styles['child__content__details']}>
                <ChatBubbles style={{ marginRight: 5 }} />
                {caller.reason}
              </div>
            )}
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 20, paddingTop: isVideo ? 10 : 0 }}>
          {!live && isHost && (
            <div style={{ marginRight: 10 }}>
              <ButtonGroup>
                <ButtonIcon onClick={() => toggleTalking(caller.guid, showGuid)} icon={startTalkingData.loading || stopTalkingData.loading ? spinner : <MicAdd width={25} height={25} />} />
              </ButtonGroup>
            </div>
          )}
          {(!live || isHost) && (
            <ButtonGroup>
              {isVideo && live && <ButtonIcon onClick={onClickVideoToggle} icon={videoToggleIcon} />}
              {live && <ButtonIcon onClick={onClickAudioToggle} icon={audioToggleIcon} />}
              <ButtonIcon onClick={() => sendToScreener(variables)} icon={sendToScreenerData.loading ? spinner : <ArrowPerson fill="#334250" width={31} height={23} />} />
              {isHost && <ButtonIcon onClick={() => stopCall(variables)} icon={stopCallData.loading ? spinner : <XIcon />} />}
            </ButtonGroup>
          )}
        </div>
      </div>
    </div>
  );
}
