import React from 'react';
import CallerControlsLive from './CallerControlsLive';
import { useStudio } from '../routes/Show.js';

export default function Board() {
    const { data } = useStudio()

    const talkers = data?.show?.liveStage?.callers?.filter(c => c.talking)
    const divider = <div style={{ height: 10, minWidth: '100%', backgroundColor: 'rgba(51, 66, 80, 0.15)' }} />

    return data?.show?.liveStage?.callers?.length || talkers?.length ? (
      <div style={{ flex: 1 }}>
        {talkers?.map(caller => <CallerControlsLive key={caller.guid} caller={caller} />)}
        {talkers?.length ? divider : null}
        {data?.show?.liveStage?.callers?.filter(caller => !caller.talking).map(c => <CallerControlsLive key={c} caller={c} />)}
      </div>
    ) : (
      <div style={{ flex: 1, padding: 20, textAlign: 'center' }}>
        <h3>No callers queued</h3>
      </div>
    );
}
