import React, { useContext } from 'react';
import { LocalMediaContext } from '../contexts/LocalMediaContext.js';
import { ReactComponent as MicActive } from '../assets/mic-active.svg';
import { ReactComponent as MicInactive } from '../assets/mic-inactive.svg';
import styles from './LargeMute.module.scss';

export default function LargeMute({ showVideoFeed, audioOnly }) {
    const { audioMuted, toggleDeviceMute, localAudioStream } = useContext(LocalMediaContext);

    return (
      <button className={styles['mute']} onClick={() => toggleDeviceMute(localAudioStream)}>
          {audioMuted ? <MicInactive height={75} width={65} fill="white" /> : <MicActive height={75} width={65} fill="#00ff4e" />}
      </button>
    );
}
