import React from 'react';
import CallerControlsScreening from './CallerControlsScreening';
import { useStudio } from '../routes/Show.js';

export default function Screening() {
  const { data, mockData } = useStudio()
  return (data?.show?.preStage?.callers?.length || mockData?.callers?.length) ? (
    <div style={{ flex: 1 }}>
      {(data?.show?.preStage?.callers ?? mockData?.callers)?.map(c => <CallerControlsScreening key={c.guid} caller={c}  />)}
    </div>
  ) : (
    <div style={{ flex: 1, padding: 20, textAlign: 'center' }}>
      <h3>No callers waiting</h3>
    </div>
  );
}
