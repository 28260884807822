import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import DashboardContainer from '../components/DashboardContainer.js';
import styles from './Dashboard.module.scss';

const GET_DASHBOARD = gql`
    query GetDashboard {
        apiVersion
        minClient
        shows {
            guid
            title
            artwork
        }
    }
`

// const data = {
//     shows: [
//         { guid: '63e32ee0-ceeb-4315-b8a8-89ec6a788342', artwork: 'https://podcast.audioroad.net/artwork/trucking-business-and-beyond-header.png', title: 'Trucking Business & Beyond' },
//         { guid: '70f78526-6016-4678-b982-b02c865d635f', artwork: 'https://podcast.audioroad.net/artwork/rolling-toe-header.png', title: 'Rolling Toe' },
//         { guid: '9285dc0b-c2c9-4706-ba72-4d4bb1122b16', artwork: 'https://podcast.audioroad.net/artwork/the-power-hour-header.png', title: 'The Power Hour' },
//         { guid: 'd214a9cf-9245-4f7b-ba00-1c2526e83166', artwork: 'https://podcast.audioroad.net/artwork/destination-health-header.png', title: 'Destination Health' },
//         { guid: 'f1817fbd-fa17-4207-a3b2-d196cac1e62e', artwork: 'https://podcast.audioroad.net/artwork/trucking-tech-header.png', title: 'Trucking Technology And Efficiency' },
//     ]
// }

export default function Dashboard() {
    const navigate = useNavigate();

    const navigateToShow = (slug, title) => {
        if (slug) {
            navigate(`/studio/${slug}`, { state: { title } });
        }
    };

    const { data } = useQuery(GET_DASHBOARD);

    return (
        <DashboardContainer>
            <div className={styles['content']}>
                {data?.shows?.map(show => {
                    return (
                        <img alt={show.title} key={show.guid} onClick={() => navigateToShow(show.guid, show.title)} className={styles['showImg']} src={show.artwork} />
                    )
                })}
            </div>
        </DashboardContainer>
    );
}
