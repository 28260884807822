import React, { createContext } from 'react';
import useStage from '../hooks/useStage';


const defaultStageContext = {
    joinStage: undefined,
    participants: [],
    stageConnected: false,
};


export const StageContext = createContext({
    ...defaultStageContext,
});


export default function StageProvider({ children }) {
    const stage = useStage();

    return <StageContext.Provider value={stage}>{children}</StageContext.Provider>;
}
