import { useState, useRef, useContext, useEffect } from 'react';
import { SubscribeType } from 'amazon-ivs-web-broadcast';
import { LocalMediaContext } from '../contexts/LocalMediaContext.js';
import Strategy from '../util/strategy.js';
import { Stage, StageConnectionState, StageEvents, StageParticipantPublishState } from 'amazon-ivs-web-broadcast';

export const STAGE_TOKEN = 'stageToken';

export default function useStage() {
    const [stageJoined, setStageJoined] = useState(false);
    const [switchStageToken, setSwitchStageToken] = useState(undefined);

    const [participants, setParticipants] = useState(new Map());
    const [localParticipant, setLocalParticipant] = useState({});
    const { localAudioStream, localVideoStream } = useContext(LocalMediaContext);

    const stageRef = useRef(undefined);
    const strategyRef = useRef(new Strategy(localAudioStream, localVideoStream));

    useEffect(() => {
        strategyRef.current.updateMedia(localAudioStream, localVideoStream);
        if (stageRef.current && stageJoined) {
            stageRef.current.refreshStrategy();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localAudioStream, localVideoStream]);

    useEffect(() => {
        console.log('switchStageToken', switchStageToken, 'stageJoined', stageJoined)
        if (switchStageToken && !stageJoined) {
            joinStage(switchStageToken).then(() => {
                setSwitchStageToken();
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [switchStageToken, setSwitchStageToken, stageJoined])

    const setAudioOnly = (audioOnly=true) => {
        strategyRef.current = new Strategy(localAudioStream, !audioOnly ? localVideoStream : undefined, audioOnly ? SubscribeType.AUDIO_ONLY : SubscribeType.AUDIO_VIDEO);
    }

const handleParticipantJoin = (person) => {
    console.log("useStage: join", person.userId);
    if (isLocalParticipant(person)) {
        setLocalParticipant(person);
    } else {
        const participant = createParticipant(person);
        // NOTE: we must make a new map so react picks up the state change
        setParticipants(new Map(participants.set(participant.id, participant)));
    }
};

const handleParticipantLeave = (person) => {
    console.log("useStage: left", person.userId);
    try {
        if (isLocalParticipant(person)) {
            setLocalParticipant({});
        } else {
            if (participants.delete(person.id)) {
                console.log("useStage: current", participants);
                setParticipants(new Map(participants));
            }
        }
    }
    catch (e) {
        console.log("useStage", e);
    }
};

const handleMediaAdded = (person, streams) => {
    console.log("useStage: media", person.userId);
    try {
        if (!isLocalParticipant(person)) {
            const { id } = person;
            let participant = participants.get(id);
            participant = { ...participant, streams: [...streams, ...participant.streams] };
            setParticipants(new Map(participants.set(id, participant)));
        }
    }
    catch (e) {
        console.log("useStage: media fail", e);
    }
};

const handleMediaRemoved = (person, streams) => {
    console.log("useStage: media removed", person.userId);
    try {
        if (!isLocalParticipant(person)) {
            const { id } = person;
            let participant = participants.get(id);
            const newStreams = participant.streams.filter(
                (existingStream) => !streams.find((removedStream) => existingStream.id === removedStream.id)
            );
            participant = { ...participant, streams: newStreams };
            setParticipants(new Map(participants.set(id, participant)));
        }
    }
    catch (e) {
        console.log("useStage: media fail", e);
    }
};

const handleParticipantMuteChange = (person, stream) => {
    console.log("useStage: toggle mute", person.userId);
    try {
        if (!isLocalParticipant(person)) {
            const { id } = person;
            let participant = participants.get(id);
            participant = { ...participant, ...person };
            setParticipants(new Map(participants.set(id, participant)));
        }
    }
    catch (e) {
        console.log("useStage: mute fail", e);
    }
};

const handleConnectionStateChange = (state) => {
    console.log("useStage: state", state);
    switch (state) {
        case StageConnectionState.CONNECTING:
            break;
        case StageConnectionState.CONNECTED:
            setStageJoined(true);
            break;
        case StageConnectionState.DISCONNECTED:
            setStageJoined(false);
            break;
        case StageConnectionState.ERRORED:
            setStageJoined(false);
            break;
        default:
            break;
    }
};

const handlePublishSubscribeChange = (person, state) => {
    console.log("useStage: pub/sub", state, person.userId);
    if (state === StageParticipantPublishState.ERRORED) {
        if (stageRef.current && stageJoined) {
            stageRef.current.refreshStrategy();
        }
    }
};

async function leaveStage() {
    try {
        if (stageRef.current) {
            await stageRef.current.leave();
            localStorage.removeItem(STAGE_TOKEN);
            localStorage.removeItem('callerState');
            console.info('remove stage token')
        }
    }
    catch (e) {
        console.log("useStage: leave fail", e);
    }
}

async function joinStage(token) {
    if (!token) { return; } // Ignore false
    try {
        const stage = new Stage(token, strategyRef.current);
        stage.on(StageEvents.STAGE_CONNECTION_STATE_CHANGED, handleConnectionStateChange);
        stage.on(StageEvents.STAGE_PARTICIPANT_JOINED, handleParticipantJoin);
        stage.on(StageEvents.STAGE_PARTICIPANT_LEFT, handleParticipantLeave);
        stage.on(StageEvents.STAGE_PARTICIPANT_STREAMS_ADDED, handleMediaAdded);
        stage.on(StageEvents.STAGE_PARTICIPANT_STREAMS_REMOVED, handleMediaRemoved);
        stage.on(StageEvents.STAGE_STREAM_MUTE_CHANGED, handleParticipantMuteChange);
        stage.on(StageEvents.STAGE_PARTICIPANT_PUBLISH_STATE_CHANGED, handlePublishSubscribeChange);
        stage.on(StageEvents.STAGE_PARTICIPANT_SUBSCRIBE_STATE_CHANGED, handlePublishSubscribeChange);

        stageRef.current = stage;

        await stageRef.current.join();
        localStorage.setItem(STAGE_TOKEN, token);
        console.info('set stage token, joining')
    } catch (err) {
        console.error('Error joining stage', err);
        alert(`Error joining stage: ${err.message}`);
    }
}

async function switchStage(token) {
    console.log('switchToken', token !== undefined, 'stageJoined', stageJoined, 'current', stageRef.current !== undefined);
    //NOTE: was stageJoined, which doesn't seem to be accurate all the time
    if (stageRef.current) {
        leaveStage();
        setSwitchStageToken(token);
        localStorage.setItem(STAGE_TOKEN, token);
        console.info('set stage token, switching')
    } else {
        await joinStage(token);
    }
}

return { joinStage, stageJoined, leaveStage, switchStage, participants, localParticipant, setAudioOnly };
}

function createParticipant(person) {
    return {
        ...person,
        streams: [],
    };
}

function isLocalParticipant(info) {
    return info.isLocal;
}
