import React from 'react';
import styles from './Tabs.module.scss'
import { usePersistentState } from '../hooks/usePersistentState';

const useTabs = (tabs, tabGroupKey, theme='light') => {
  const [activeTab, setActiveTab] = usePersistentState(tabGroupKey, 0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const TabMenu = () => (
    <ul className={styles['tab-menu']}>
      {tabs.map((t, idx) => {
        const active = activeTab === idx && t.label
        return (
          <li
            key={t.label}
            className={`${styles[`tab-item-${theme}`]} ${active && styles[`tab-item-${theme}__active`]}`}
            onClick={() => handleTabClick(idx)}>
              {t.label ?? t.icon}
              {t.notificationCount > 0 && !active && (
              <div className={styles[`tab-item-${theme}__notification`]}>
                {t.notificationCount}
              </div>
            )}
          </li>
        )
      })}
    </ul>
  )

  const TabContent = () => (
    <div className={styles['tab-content']}>
        {tabs[activeTab]?.content}
    </div>
  )

  return {
    TabMenu,
    TabContent,
  }
};

export default useTabs;