import { useState } from 'react';
import { getCamera, getMic } from '../util/mediaDevices.js';
import { LocalStageStream, StreamType } from 'amazon-ivs-web-broadcast';

export function useLocalMedia() {
    const [localAudioStream, setLocalAudioStream] = useState(undefined);
    const [localVideoStream, setLocalVideoStream] = useState(undefined);
    const [selectedAudio, setSelectedAudio] = useState(undefined);
    const [selectedVideo, setSelectedVideo] = useState(undefined);

    async function setLocalAudio(val) {
        setSelectedAudio(val);
        const audioTrack = await getMic(val);
        createLocalStream(audioTrack);
    }

    async function setLocalVideo(val) {
        setSelectedVideo(val);
        const videoTrack = await getCamera(val);
        createLocalStream(videoTrack);
    }

    function createLocalStream(track) {
        if (!track) {
            console.warn('tried to set local media with a null track');
            return;
        }
        const stream = new LocalStageStream(track, { simulcast: { enabled: true } });
        if (stream.streamType === StreamType.VIDEO) {
            setLocalVideoStream(stream);
        } else {
            setLocalAudioStream(stream);
        }
    }

    return {
        localAudioStream,
        localVideoStream,
        selectedAudio,
        selectedVideo,
        setLocalAudio,
        setLocalVideo,
    };
}
