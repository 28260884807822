import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useAuth } from '../contexts/AuthContext.js';
import Background from '../components/Background.js';
import AudioroadNetwork from '../assets/audioroad-network.svg';
import LoadingSpinner from '../components/LoadingSpinner.js';
import styles from './Login.module.scss';
import { GET_ME } from '../util/graphql.js';

export default function Login() {
    // State to store email and password
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const { user, token, setToken, setUser } = useAuth();

    const { data, loading: meLoading } = useQuery(GET_ME, {
        skip: !token,
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            data?.me && setUser(data.me)
        }
    });

    // User should set in onCompleted, this is for redundancy
    useEffect(() => {
        if (data?.me) {
            setUser(data.me)
        }
    }, [data, setUser])

    useEffect(() => {
        // Check if token exists in local storage to handle mobile app injection
        const t = localStorage.getItem('token')
        if (t && !token) {
            setToken(t)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const navigate = useNavigate();
    let location = useLocation();
    let query = new URLSearchParams(location.search);
    let showGuid = query.get("show_guid");

    useEffect(() => {
        const redirect = localStorage.getItem('redirectURL')
        if (user?.guid && token) {
            if (redirect) {
                navigate(redirect)
                localStorage.removeItem('redirectURL')
            } else if (showGuid) {
                navigate(`/caller/${showGuid}`);
            } else if (user.role === 'host' || user.role === 'screener') {
                navigate("/studio");
            } else {
                navigate("/live");
            }
        }
    }, [user, token, navigate, showGuid]);

    // State to store any login errors
    const [error, setError] = useState("");

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevents the default form submission behavior
        setLoading(true);
        // Make API call to login endpoint
        try {
            let environ = process.env.REACT_APP_ENVIRON;
            let host =
                environ !== "prod"
                    ? `api-${environ}.letstruck.com`
                    : "api.letstruck.com";

            const response = await fetch(`https://${host}/api/login`, {
                method: "POST",
                mode: "cors",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email, password }),
            });

            const data = await response.json();
            if (response.ok) {
                // Handle successful login here
                if (data.token) {
                    setToken(data.token)
                } else {
                    setLoading(false);
                }
            } else {
                setLoading(false);
                // Handle errors, such as displaying a message to the user
                setError(data.message || "Login failed");
            }
        } catch (error) {
            // Handle network errors
            setError("Network error");
            setLoading(false);
        }
    };

    const spinner = (
        <i style={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: 50, margin: '20px' }} className="fa fa-circle-o-notch fa-spin" />
    )

    return (
        <Background>
            <div className={styles["container"]}>
                <div className={styles["content"]}>
                    <div className={styles["logo"]}>
                        <img src={AudioroadNetwork} alt={"AudioRoad Network"} />
                    </div>
                    {meLoading ? (
                        <div style={{ alignSelf: 'center', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {spinner}
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit} className={styles["form"]}>
                            {error && <p className={styles["error"]}>{error}</p>}
                            <>
                                <input
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className={styles["input"]}
                                />
                                <input
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className={styles["input"]}
                                />
                                {email && password && (
                                    <button
                                        disabled={loading}
                                        type="submit"
                                        className={styles["submit"]}
                                    >
                                        <LoadingSpinner loading={loading}>Sign In</LoadingSpinner>
                                    </button>
                                )}
                            </>
                        </form>
                    )}
                </div>
            </div>
        </Background>
    );
}
