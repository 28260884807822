import React, { createContext, useState, useEffect } from 'react';
import { getDevices } from '../util/mediaDevices.js';
import { useLocalMedia } from '../hooks/useLocalMedia.js';
import { StreamType } from 'amazon-ivs-web-broadcast';


export const LocalMediaContext = createContext({
    audioDevices: [],
    videoDevices: [],
});

export default function LocalMediaProvider({ children }) {
    const [audioDevices, setAudioDevices] = useState([]);
    const [videoDevices, setVideoDevices] = useState([]);
    const [audioMuted, setAudioMuted] = useState(false);
    const [videoMuted, setVideoMuted] = useState(false);
    const {selectedAudio, selectedVideo, localAudioStream, localVideoStream, setLocalAudio, setLocalVideo} = useLocalMedia();

    function setDeviceMute(device, mute) {
        if (!device) {
            console.log('no device to set mute')
            return;
        }
        console.log('set device mute', mute, device)

        device.setMuted(mute);
        if (device.streamType === StreamType.VIDEO) {
            setVideoMuted(mute);
        } else {
            setAudioMuted(mute);
        }
    }

    function toggleDeviceMute(device) {
        if (!device) {
            console.log('no device');
            return;
        }
        setDeviceMute(device, !device.isMuted)
    }

    // Different than audioMuted state, but still controls it
    function setAudioMute(muted) {
        setDeviceMute(localAudioStream, muted)
    }

    // Different than videoMuted state, but still controls it
    function setVideoMute(muted) {
        setDeviceMute(localVideoStream, muted)
    }

    async function initializeMedia(video = false, audio = true) {
        const { videoDevices, audioDevices } = await getDevices(video, audio);

        audio && setLocalAudio(audioDevices[0].deviceId);
        video && setLocalVideo(videoDevices[0].deviceId);
        setAudioDevices(
            audioDevices.map((device) => {
                return { label: device.label, value: device.deviceId };
            })
        );
        setVideoDevices(
            videoDevices.map((device) => {
                return { label: device.label, value: device.deviceId };
            })
        );
    }

    useEffect(() => {
        initializeMedia(false, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const state = {
        initializeMedia,
        selectedAudio,
        selectedVideo,
        audioDevices,
        videoDevices,
        localAudioStream,
        localVideoStream,
        setLocalAudio,
        setLocalVideo,
        toggleDeviceMute,
        setDeviceMute,
        audioMuted,
        videoMuted,
        setAudioMute,
        setVideoMute,
    };

    return <LocalMediaContext.Provider value={state}>{children}</LocalMediaContext.Provider>;
}
