import React, { useRef, useEffect } from 'react';
import Video from './Video.js';
import { StreamType } from 'amazon-ivs-web-broadcast';
import LocalVideo from '../components/LocalVideo.js';

export default function Participant({ userId, streams }) {
    const videoStream = streams?.find((stream) => stream.streamType === StreamType.VIDEO);
    const audioStream = streams?.find((stream) => stream.streamType === StreamType.AUDIO);

    const audioRef = useRef(undefined);

    useEffect(() => {
        if (audioRef.current && audioStream) {
            audioRef.current.srcObject = new MediaStream([audioStream.mediaStreamTrack]);
        }
    }, [audioRef, audioStream]);

    const wrapperStyle = {
        position: 'absolute',
        overflow: 'hidden',
        height: '100%',
        width: '100%',
        borderRadius: '8px',
    }

    const videoFeed = userId === 'local' ? <LocalVideo /> : <Video stageStream={videoStream} />

    return (
        <div id="local-media" style={wrapperStyle}>
            {(!!videoStream || userId === 'local') && videoFeed}
            <audio ref={audioRef} autoPlay />
        </div>
    );
}
