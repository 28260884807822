import React from 'react';
import { useAuth } from '../contexts/AuthContext.js';
import { Sidebar } from './Sidebar.js';
import { Header } from './Header.js';
import AudioroadNetwork from '../assets/audioroad-network.svg';
import ProfileImg from './ProfileImg.js';
import styles from './DashboardContainer.module.scss';

export default function DashboardContainer({ children, caller }) {
    const { user } = useAuth();

    const sideItems = caller ? (
        <>
            <Sidebar.Item to={`/caller`} label="Dashboard" />
        </>
    ) : (
        <>
            <Sidebar.Item to={`/studio`} label="Studio" />
            <Sidebar.Item to={`/caller`} label="Caller" />
        </>
    )

    return (
        <div className={styles['container']}>
            <Header>
                <div className={styles['container__header']}>
                    <img alt="audioroad network" src={AudioroadNetwork} style={{ width: 224 }} />
                    <ProfileImg contextMenu user={user} size={50} style={{ border: '1px solid rgba(255, 255, 255, 0.2)', marginLeft: 20 }} />
                </div>
            </Header>
            <div style={{ flex: 1, height: 'calc(100vh - 90px)', display: 'flex' }}>
                <Sidebar>
                    {sideItems}
                </Sidebar>
                {children}
            </div>
        </div>
    );
}
