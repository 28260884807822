import React, { useEffect, useState, useRef } from 'react';
import styles from './ProfileImg.module.scss';
import { Tooltip } from 'react-tooltip';
import { useAuth } from '../contexts/AuthContext.js';
import { useApolloClient } from '@apollo/client';

export default function ProfileImg({ user, size = 72, style, onLogout, showTooltip = true, contextMenu }) {
  const { logout } = useAuth();
  const client = useApolloClient();
  const [showMenu, setShowMenu] = useState(false);
  const ref = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowMenu(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);


  function onClick() {
    setShowMenu(!showMenu);
  }

  const menu = (
    <div ref={ref} className={styles['contextMenu']}>
      {user?.name && <div className={styles['menuItem']} style={{ fontWeight: '700' }}>{user?.name}</div>}
      <div className={styles['menuItem']} onClick={() => {
        onLogout?.()
        logout(client)
      }}>Logout</div>
    </div>
  )

  return (
    <div
      onClick={onClick}
      data-tooltip-id={`profileImg-${user?.guid}`}
      className={styles.img}
      style={{
        cursor: contextMenu ? 'pointer' : 'auto',
        height: size,
        width: size,
        backgroundColor: `rgba(150, 150, 150, ${user?.profileImage ? '0' : '1'})`,
        backgroundImage: `url(${user?.profileImage})`,
        ...style,
      }}>
      {!user?.profileImage && user?.initials && (
        <div style={{ fontSize: size / 2 }} className={styles['initials']}>{user.initials}</div>
      )}
      {(user?.name || user?.initials) && showTooltip && <Tooltip id={`profileImg-${user?.guid}`}>{user?.name ?? user?.initials}</Tooltip>}
      {contextMenu && showMenu && menu}
    </div>
  );
}
