import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import styles from './ShowSettings.module.scss';
import { LocalMediaContext } from '../contexts/LocalMediaContext.js';
import { useAuth } from '../contexts/AuthContext.js';
import Select from './Select';
import { ReactComponent as MicSquare } from '../assets/mic-subtract-square.svg';
import { ReactComponent as PhoneSquare } from '../assets/phone-subtract-square.svg';
import { ReactComponent as CameraSquare } from '../assets/camera-subtract-square.svg';
import useTabs from '../components/Tabs.js';
import LocalVideo from '../components/LocalVideo.js';
import { ALLOW_CALLERS, UPDATE_AUDIO_ONLY, UPDATE_SYSTEM } from '../util/graphql.js';

const CALL_SYSTEMS = [
  { label: 'Studio', value: 'studio' },
  { label: 'Blog Talk Radio', value: 'blogtalk' },
  { label: 'Call In Studio', value: 'callinstudio' },
]

const GeneralSettings = ({ show }) => {
  const { user } = useAuth();
  const [ allowCallers, allowCallersData ] = useMutation(ALLOW_CALLERS, { refetchQueries: ['getShow'] });
  const [ updateAudio, updateAudioData ] = useMutation(UPDATE_AUDIO_ONLY, { refetchQueries: ['getShow'] });
  const [ updateSystem, updateSystemData ] = useMutation(UPDATE_SYSTEM, { refetchQueries: ['getShow'] });
  const [ selectedSystem, setSelectedSystem ] = useState(show?.callSystem);

  const spinner = (
    <i style={{ color: 'rgba(255, 255, 255, 0.75)', fontSize: 20 }} className="fa fa-circle-o-notch fa-spin" />
  )

  const handleUpdateSystem = val => {
    setSelectedSystem(val);
    updateSystem({ variables: { show: show?.guid, system: val }});
  }

  return (
    <div className={styles['container']}>
      <div className={styles['container__content']}>
        <div className={styles['section']}>
          <div className={styles['section__header']}>
            <PhoneSquare fill="#FF8900" />
            <div className={styles['section__header__title']}>Calls</div>
          </div>
          <div className={styles['section__content']}>
            <button className={styles['footer__submit']} onClick={() => allowCallers({ variables: { show: show?.guid, allow: !show?.allowCallers } })}>
              {allowCallersData.loading
                ? spinner
                : show?.allowCallers ? 'Close Phone Line' : 'Open Phone Line'
              }
            </button>
          </div>
        </div>
        <div className={styles['section']}>
          <div className={styles['section__header']}>
            <MicSquare fill="#FF8900" />
            <div className={styles['section__header__title']}>Media</div>
          </div>
          <div className={styles['section__content']}>
            <button className={styles['footer__submit']} onClick={() => updateAudio({ variables: { show: show?.guid, value: !show?.audioOnly } })}>
              {updateAudioData.loading
                ? spinner
                : show?.audioOnly ?? true ? 'Turn on Video' : 'Turn off Video'
              }
            </button>
          </div>
        </div>
      </div>
      {user.role === 'host' && (
        <div className={styles['section']}>
          <div className={styles['section__header']}>
            <div className={styles['section__header__title']}>System</div>
          </div>
          <div className={styles['section__content']}>
            <Select loading={updateSystemData.loading} options={CALL_SYSTEMS} value={selectedSystem} onChange={handleUpdateSystem} />
          </div>
        </div>
      )}
    </div>
  );
};

const VideoSettings = ({ audioOnly=true }) => {
  const { selectedAudio, selectedVideo, audioDevices, videoDevices, setLocalAudio, setLocalVideo } = useContext(LocalMediaContext);

  return (
    <div className={styles['container']}>
      {!audioOnly && (
        <div className={styles['container__video']}>
          <LocalVideo />
        </div>
      )}
      <div className={styles['container__content']}>
        <div className={styles['section']}>
          <div className={styles['section__header']}>
            <MicSquare fill="#FF8900" />
            <div className={styles['section__header__title']}>MICROPHONE</div>
          </div>
          <div className={styles['section__content']}>
            <Select value={selectedAudio} options={audioDevices} onChange={setLocalAudio} title={'Choose Microphone'} />
          </div>
        </div>
        {!audioOnly && (
          <div className={styles['section']}>
            <div className={styles['section__header']}>
              <CameraSquare fill="#FF8900" />
              <div className={styles['section__header__title']}>CAMERA</div>
            </div>
            <div className={styles['section__content']}>
              <Select value={selectedVideo} options={videoDevices} onChange={setLocalVideo} title={'Choose Camera'} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};


export default function ShowSettings({ show }) {

  const tabs = [
    {
      id: 1,
      label: 'General',
      content: <GeneralSettings show={show} />,
    },
    {
      id: 2,
      label: show?.audioOnly ?? true ? 'Audio' : 'Audio / Video',
      content: <VideoSettings audioOnly={show?.audioOnly} />,
    },
  ];

  const { TabMenu, TabContent } = useTabs(tabs, 'settingsTabs', 'dark')

  return (
    <>
      <div className={styles['header']}>
        <div className={styles['header__nav']}>
          <div className={styles['header__nav__tabs']}>
            <TabMenu />
          </div>
        </div>
      </div>
      <TabContent />
    </>
  );
}
