import { gql } from '@apollo/client';

export const GET_ME = gql`
  query getMe {
    me {
      guid
      name
      initials
      profileImage
      role
    }
  }
`

export const GET_SCREENERS = gql`
  query getScreeners($showGuid: String!) {
    online(showGuid: $showGuid) {
      guid
      name
      initials
      profileImage
    }
  }
`

export const GET_CALLER = gql`
  query getCaller($guid: String!) {
    caller(guid: $guid) {
      guid
      name
      initials
      profileImage
      reason
      location
      muteAudio
      muteVideo
      stage
      talking
      token
    }
  }
`

export const GET_SHOW_CALLER = gql`
  query getShowCaller($guid: String!) {
    show(guid: $guid) {
      guid
      title
      active
      slug
      allowCallers
      audioOnly
      onAirAt
    }
  }
`

export const GET_SHOW = gql`
  query getShow($guid: String!) {
    show(guid: $guid) {
      guid
      title
      active
      slug
      allowCallers
      audioOnly
      onAirAt
      callSystem
      callTarget
      liveStage {
        guid
        callers {
          guid
          name
          initials
          profileImage
          reason
          location
          muteAudio
          muteVideo
          stage
          talking
          token
        }
      }
      preStage {
        guid
        callers {
          guid
          name
          initials
          profileImage
          reason
          location
          muteAudio
          muteVideo
          stage
          talking
          token
        }
      }
    }
  }
`

export const START_CALL = gql`
  mutation startCall($showGuid: String!, $uid: String) {
    startCall(showGuid: $showGuid, uid: $uid) {
      result
    }
  }
`

export const STOP_CALL = gql`
  mutation stopCall($showGuid: String!, $uid: String) {
    stopCall(showGuid: $showGuid, uid: $uid) {
      result
    }
  }
`

export const START_TALKING = gql`
    mutation startTalking($showGuid: String!, $uid: String!) {
        startTalking(showGuid: $showGuid, uid: $uid) {
            result
        }
    }
`

export const STOP_TALKING = gql`
    mutation stopTalking($showGuid: String!, $uid: String!) {
        stopTalking(showGuid: $showGuid, uid: $uid) {
            result
        }
    }
`

export const SEND_TO_HOST = gql`
  mutation sendToHost($showGuid: String!, $uid: String!, $reason: String!, $location: String!, $name: String) {
    sendToHost(showGuid: $showGuid, uid: $uid, reason: $reason, location: $location, name: $name) {
      result
    }
  }
`

export const SEND_TO_SCREENER = gql`
  mutation sendToScreener($showGuid: String!, $uid: String!) {
    sendToScreener(showGuid: $showGuid, uid: $uid) {
      result
    }
  }
`

export const START_LIVE = gql`
  mutation startLive($showGuid: String!) {
    startLive(guid: $showGuid) {
      result
    }
  }
`

export const STOP_LIVE = gql`
  mutation stopLive($showGuid: String!) {
    stopLive(guid: $showGuid) {
      result
    }
  }
`

export const ALLOW_CALLERS = gql`
  mutation allowCallers($show: String!, $allow: Boolean!) {
    allowCallers(show: $show, allow: $allow) {
      result
    }
  }
`

export const UPDATE_AUDIO_ONLY = gql`
  mutation updateAudio($show: String!, $value: Boolean!) {
    updateAudio(show: $show, value: $value) {
      result
    }
  }
`

export const MUTE = gql`
  mutation mute($guid: String!, $uid: String, $audio: Boolean, $video: Boolean) {
    mute(guid: $guid, uid: $uid, audio: $audio, video: $video) {
      result
    }
  }
`

export const UPDATE_SYSTEM = gql`
  mutation ($show: String!, $system: String!) {
    updateSystem(show: $show, system: $system) {
      guid
      callSystem
      callTarget
    }
  }
`

// export const MUTE_VIDEO = gql`
// `
