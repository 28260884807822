import React from 'react';
import styles from './Button.module.scss';

export const ButtonGroup = ({ children }) => {
  return (
    <div className={styles['buttonGroup']}>
      {children}
    </div>
  )
}

export const ButtonIcon = ({ icon, onClick }) => {
  const clickWrapper = (e) => {
    e.stopPropagation()
    onClick?.()
  }
  return (
    <button className={styles['groupButton']} onClick={clickWrapper}>
      <div style={{ display: 'flex', width: 31, justifyContent: 'center', margin: '0 5px' }}>
        {icon}
      </div>
    </button>
  )
}