import React, { useEffect, useState } from "react";
import useWebSocket from "react-use-websocket";
import { useAuth } from "../contexts/AuthContext.js";
import { GET_SCREENERS } from "../util/graphql.js";
import { useQuery } from "@apollo/client";
import ProfileImg from "../components/ProfileImg.js";

// Documentation: https://github.com/robtaussig/react-use-websocket#readme

export default function WebSocket({
  showGuid,
  onCommand,
  userType,
  screeners,
  setScreeners,
}) {
  const environ = process.env.REACT_APP_ENVIRON;
  const uri =
    environ !== "prod"
      ? `wss://ws-${environ}.audioroad.net`
      : "wss://ws.audioroad.net";

  const { token } = useAuth();
  const { sendJsonMessage, lastJsonMessage } = useWebSocket(uri, {
    onOpen: () => {
      sendJsonMessage({
        action: "connect",
        token: token,
        show: showGuid,
        type: userType ? userType : "caller",
      });
    },
    shouldReconnect: (event) => {
      return true;
    },
    reconnectAttempts: 3,
    reconnectInterval: 3000,
  });
  const { data } = useQuery(GET_SCREENERS, {
    variables: {
      showGuid,
    },
    skip: userType === undefined || userType !== "screener",
  });

  useEffect(() => {
    if (data?.online) {
      setScreeners(data.online);
    }
  }, [setScreeners, data]);

  useEffect(() => {
    if (lastJsonMessage !== null) {
      onCommand(lastJsonMessage);
    }
  }, [lastJsonMessage, onCommand]);

  const [isSpread, setIsSpread] = useState(false);
  const toggleSpread = () => setIsSpread(!isSpread);

  return (
    <div
      style={{
        display: "flex",
        cursor: "pointer",
        transition: "transform 0.5s ease",
        zIndex: 10,
        transform: isSpread
          ? `translateX(${
              screeners?.length > 1 ? 35 - 35 * screeners?.length : 0
            }px)`
          : "",
      }}
      onClick={toggleSpread}
    >
      {screeners?.map((screener, index) => {
        return (
          <ProfileImg
            key={screener.guid}
            user={screener}
            size={50}
            style={{
              border: "1px solid rgba(255, 255, 255, 0.2)",
              marginLeft: -25,
              transition: "transform 0.5s ease",
              transform: isSpread ? `translateX(${35 * index}px)` : "",
            }}
          />
        );
      })}
    </div>
  );
}
