import React from 'react';
import styles from './Select.module.scss'


export default function Select({ title, options, onChange, value, loading }) {

    const handleChange = async (e) => {
        await onChange(e.target.value);
    };

    return (
        <>
            <label className={styles['select-label']} htmlFor={title}>{title}</label>
            <select
                id={title}
                className={`${styles['select']} ${loading ? styles['select-loading'] : ''}`}
                onChange={handleChange}
                disabled={loading}
                value={value}>
                {
                    options.map((option) => {
                        return (
                            <option key={option.value} value={option.value}>{option.label}</option>
                        );
                    })
                }
            </select>
        </>
    );
}
