import React, { useMemo, useRef, useEffect, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import styles from './CallerControls.module.scss';
import { ReactComponent as XIcon } from '../assets/x.svg';
import { ReactComponent as People } from '../assets/people.svg';
import { ReactComponent as MapPin } from '../assets/map-pin.svg';
import { ReactComponent as ChatBubbles } from '../assets/chat-bubbles.svg';
import { ReactComponent as ArrowPerson } from '../assets/arrow-person.svg';
import ProfileImg from './ProfileImg.js';
import { useStudio } from '../routes/Show.js';
import { useAuth } from '../contexts/AuthContext.js';
import { STOP_CALL, START_TALKING, STOP_TALKING, SEND_TO_HOST } from '../util/graphql.js';
import { StreamType } from 'amazon-ivs-web-broadcast';
import Video from './Video.js';
import { ButtonGroup, ButtonIcon } from './ButtonGroup.js';

export default function CallerControlsScreening({ caller }) {
  const { setCaller, showGuid, setPreviewCaller, isLive, joinStage, stageJoined, leaveStage, participants } = useStudio()
  const { user } = useAuth();

  const isHost = user?.role === 'host' ?? false;

  const refetchQueries = ['getShow'];

  const [stopCall, stopCallData] = useMutation(STOP_CALL, { refetchQueries });
  const [startTalking, startTalkingData] = useMutation(START_TALKING, { refetchQueries });
  const [stopTalking, stopTalkingData] = useMutation(STOP_TALKING, { refetchQueries });
  const [sendToHost, sendToHostData] = useMutation(SEND_TO_HOST, { refetchQueries: ['getShow'] });

  const screeningParticipant = useMemo(() => {
    return [...participants?.values()].find(participant => {
      // console.log('PARTICIPANT', participant)
      let [t, u] = participant.userId?.split('-');
      // console.log('participant.id split (', t, ':', u, ') data caller guid', caller.guid)
      return t === 'caller' && u === caller.guid && caller.stage === 'pre'
    })
  }, [participants, caller]);

  const videoStream = screeningParticipant?.streams?.find((stream) => stream.streamType === StreamType.VIDEO);
  const audioStream = screeningParticipant?.streams?.find((stream) => stream.streamType === StreamType.AUDIO);

  const isVideo = !!videoStream && !screeningParticipant?.videoStopped;
  const audioRef = useRef(undefined);

  useEffect(() => {
    if (audioRef.current && audioStream) {
      audioRef.current.srcObject = new MediaStream([audioStream.mediaStreamTrack]);
    }
  }, [audioRef, audioStream]);

  const spinner = (
    <i style={{ color: 'rgba(51, 66, 80, 0.75)', fontSize: 20 }} className="fa fa-circle-o-notch fa-spin" />
  )

  const doStopCall = async (userId, showGuid) => {
    await leaveStage();
    await stopCall({ variables: { uid: userId, showGuid } });
    setCaller();
  };

  const toggleScreening = useCallback(async (uid, showGuid) => {
    if (isLive) {
      alert('Oops, you are unable to screen and host at the same time.');
      return;
    }
    console.log('caller?.talking', caller?.talking, 'stageJoined', stageJoined)
    if (stageJoined) {
      console.log('stop talking')
      let resp = await stopTalking({
        variables: {
          showGuid: String(showGuid),
          uid: String(uid),
        }
      }).catch(e => console.log('stopTalking error:', e))
      console.log('STOP TALKING RESPONSE', resp)
      if (resp.data?.stopTalking.result) {
        console.log('remove caller')
        setCaller()
      }
      console.log("leave screening stage");
      await leaveStage();
      console.log('left stage')
    } else {
      console.log('start talking')
      let response = await startTalking({
        variables: {
          showGuid: String(showGuid),
          uid: String(uid),
        }
      }).catch(e => console.log('startTalkingError:', e));
      if (response?.data?.startTalking?.result) {
        console.log('join the stage')
        await joinStage(response.data.startTalking.result);
        console.log('set caller')
        setCaller(caller)
      }
      console.log("enter screening stage");
    }
  }, [caller, setCaller, isLive, joinStage, leaveStage, stageJoined, startTalking, stopTalking]);

  const approveCaller = async () => {
    await sendToHost({
      variables: {
        showGuid,
        uid: String(caller?.guid),
        reason: caller?.reason ?? '',
        location: caller?.location ?? '',
        name: caller?.name ?? '',
      }
    })
  }

  function handleSetPreview() {
    setPreviewCaller(prev => prev?.guid === caller?.guid ? null : caller)
  }

  return (
    <div className={styles['child']} onClick={handleSetPreview}>
      {!isVideo ? (
        <ProfileImg user={caller} size={72} />
      ) : (
        <div className={styles['child__video']}>
          <Video stageStream={videoStream} />
        </div>
      )}
      {stageJoined && <audio ref={audioRef} autoPlay />}
      <div className={styles['child__content']} style={{ flexDirection: isVideo ? 'column' : 'row', alignItems: isVideo ? 'flex-start' : 'center' }}>
        <div style={{ flex: 1, paddingLeft: 20, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <div style={{ margin: 0, fontSize: 25, fontWeight: '500', color: 'black', lineHeight: 1.4 }}>{caller.name ?? 'New Caller'}</div>
          <div>
            {caller.location && (
              <div className={styles['child__content__details']}>
                <MapPin style={{ marginRight: 5 }} />
                {caller.location}
              </div>
            )}
            {caller.reason && (
              <div className={styles['child__content__details']}>
                <ChatBubbles style={{ marginRight: 5 }} />
                {caller.reason}
              </div>
            )}
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 20, paddingTop: isVideo ? 10 : 0, justifyContent: 'flex-end', width: isVideo ? '100%' : 'auto' }}>
          <div style={{ flex: 1 }} />
          <ButtonGroup>
            {isHost && (
              <ButtonIcon
                onClick={approveCaller}
                icon={sendToHostData.loading
                  ? spinner
                  : <ArrowPerson fill="rgba(51, 66, 80, 0.5)" width={31} height={23} style={{ transform: 'scaleX(-1)' }} />
                }
              />
            )}
            <ButtonIcon
              onClick={() => toggleScreening(caller.guid, showGuid)}
              icon={startTalkingData.loading || stopTalkingData.loading
                ? spinner
                : <People fill={caller?.talking
                  ? '#1ECA3E'
                  : 'rgba(51, 66, 80, 0.5)'} width={31} height={23} />
              }
            />
            <ButtonIcon
              onClick={() => doStopCall(caller.guid, showGuid)}
              icon={stopCallData.loading ? spinner : <XIcon />}
            />
          </ButtonGroup>
        </div>

      </div>
    </div>
  );
}
