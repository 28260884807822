import React, { useContext } from 'react';
import { LocalMediaContext } from '../../contexts/LocalMediaContext.js';
import DarkSelect from '../../components/DarkSelect.js';
import { ReactComponent as CameraIcon } from '../../assets/camera-active.svg';
import { ReactComponent as MicIcon } from '../../assets/mic-active.svg';
import styles from '../Caller.module.scss';

export default React.forwardRef(({ close, show, audioOnly }, ref) => {
  const { selectedAudio, selectedVideo, audioDevices, videoDevices, setLocalAudio, setLocalVideo } = useContext(LocalMediaContext);
  return (
    <div ref={ref} className={styles['settings-modal']} onClick={close} style={{ height: show ? 290 : 1, opacity: show ? 1 : 0 }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '15px 20px', paddingBottom: 10,borderBottom: '1px solid rgba(51, 66, 80, 0.5)' }}>
        <div style={{ fontFamily: 'Roboto', fontSize: 30, fontWeight: '900', color: '#334250' }}>Audio Settings</div>
      </div>
      <div style={{ maxWidth: 350, margin: 'auto', padding: 10 }}>
        {!audioOnly && (
          <>
            <DarkSelect value={selectedVideo} options={videoDevices} onChange={setLocalVideo} icon={<CameraIcon width={25} fill="rgba(51, 66, 80, 1)"/>} />
            <div style={{ height: 10 }} />
          </>
        )}
        <DarkSelect value={selectedAudio} options={audioDevices} onChange={setLocalAudio} icon={<MicIcon width={16} fill="rgba(51, 66, 80, 1)"/>} />
      </div>
    </div>
  );
})
