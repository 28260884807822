import React from 'react';
import styles from './LoadingSpinner.module.scss';

const LoadingSpinner = ({ children, loading }) => {
    return (
      <div className={`${styles['loading-button']} ${loading ? styles['is-loading'] : ''}`}>
        {loading ? <div className={styles['spinner']}></div> : children}
      </div>
    );
};
export default LoadingSpinner;
