import React, { useEffect, useState, useContext, createContext, useMemo, useCallback, useRef } from 'react';
import { debounce } from "lodash";
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Header } from '../components/Header.js';
import { useAuth } from '../contexts/AuthContext.js';
import MediaControls from '../components/MediaControls.js';
import useTabs from '../components/Tabs.js';
import Board from '../components/Board.js';
import Screening from '../components/Screening.js';
import ScreeningDetails from '../components/ScreeningDetails.js';
import CallerPreview from '../components/CallerPreview.js';
import ProfileImg from '../components/ProfileImg.js';
import styles from './Show.module.scss';
import { ReactComponent as DotsOutline } from '../assets/dots-outline.svg';
import ShowSettings from '../components/ShowSettings.js';
import { GET_SHOW, START_LIVE, STOP_LIVE } from '../util/graphql.js';
import WebSocket from '../components/WebSocket.js';
import TimerDisplay from '../components/Timer.js';
import { StageContext } from '../contexts/StageContext.js';
import { LocalMediaContext } from '../contexts/LocalMediaContext.js';
import ScreenerView from '../components/ScreenerView.js';
import { STAGE_TOKEN } from '../hooks/useStage.js';

// const mock = [
//   { name: 'Ben Fisher', guid: '1234', initials: 'BF', location: 'Chubbuck, ID', reason: 'I need help' },
//   { name: 'Richard Barton', guid: '3456', initials: 'RB', location: 'Ucon, ID', reason: 'My truck won\'t start' },
//   { name: 'Aaron Yount', guid: '5678', initials: 'AY', location: 'City, CO', reason: 'I have some problems' },
// ]

const StudioContext = createContext()
export const useStudio = () => useContext(StudioContext)

function ShowContent({ data, showVideo }) {
  const { user } = useAuth();
  const navigate = useNavigate();

  const ROLE = user?.role ?? 'screener';

  const { show_guid } = useParams();

  const [isLive, setIsLive] = useState(false);
  const [hostIsLive, setHostIsLive] = useState(false);
  const [caller, setCaller] = useState();
  const [previewCaller, setPreviewCaller] = useState();
  const [showCallerDetails, setShowCallerDetails] = useState(false);
  const [showPreviewCallerDetails, setShowPreviewCallerDetails] = useState(false);
  const [screeners, setScreeners] = useState([]);
  const [loadingLive, setLoadingLive] = useState(false);

  const stage = useContext(StageContext);

  useEffect(() => {
    stage.setAudioOnly(data?.show?.audioOnly ?? !showVideo)
  }, [showVideo, stage, data?.show?.audioOnly])

  const [startLive] = useMutation(START_LIVE);
  const [stopLive] = useMutation(STOP_LIVE);

  useEffect(() => {
    const stageToken = localStorage.getItem(STAGE_TOKEN);
    if (stageToken && !stage.stageJoined) {
      setTimeout(() => {
        console.info('rejoining stage')
        stage.joinStage(stageToken);
      }, 2000)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (caller?.guid) {
      const callerGuids = data?.show.preStage.callers?.map(c => c.guid);

      let isCallerPresent;
      if (Array.isArray(callerGuids)) {
        // If callerGuids is an array, check if it includes caller.guid
        isCallerPresent = callerGuids.includes(caller.guid);
      } else if (callerGuids && typeof callerGuids === 'object') {
        // If callerGuids is an object, check if caller.guid is a key in the object
        isCallerPresent = Object.keys(callerGuids).includes(caller.guid);
      }

      // If the caller is not present, update the state
      if (!isCallerPresent) {
        console.log('caller is not present, remove screening details')
        setCaller();

        // If not live and still in screening stage, leave the stage
        if (!isLive && stage.stageJoined) {
          stage.leaveStage()
        }
      }
    }
  }, [data, caller, isLive, stage]);

  useEffect(() => {
    let timeoutId;

    if (caller) {
      setShowCallerDetails(true);
    } else {
      // Start the slide-off animation
      timeoutId = setTimeout(() => {
        // Only hide the component after the animation duration
        setShowCallerDetails(false);
      }, 500);
    }

    // Cleanup the timeout if the component unmounts or if caller changes again before the animation completes
    return () => clearTimeout(timeoutId);
  }, [caller]);

  useEffect(() => {
    let timeoutId;

    if (previewCaller) {
      setShowPreviewCallerDetails(true);
    } else {
      // Start the slide-off animation
      timeoutId = setTimeout(() => {
        // Only hide the component after the animation duration
        setShowPreviewCallerDetails(false);
      }, 500);
    }

    // Cleanup the timeout if the component unmounts or if caller changes again before the animation completes
    return () => clearTimeout(timeoutId);
  }, [previewCaller]);


  const toggleLive = useCallback(async () => {
    setLoadingLive(true)
    if (data?.show?.onAirAt && ROLE === 'host') {
      await stage.leaveStage();
      await stopLive({ variables: { showGuid: show_guid } });
    }
    else {
      let response = await startLive({ variables: { showGuid: show_guid } });
      await stage.joinStage(response?.data?.startLive?.result);
    }
    setIsLive(!isLive);
    setLoadingLive(false);
  }, [isLive, stage, show_guid, startLive, stopLive, data?.show?.onAirAt, ROLE]);


  const contextValue = useMemo(() => ({
    caller,
    setCaller,
    previewCaller,
    setPreviewCaller,
    showGuid: show_guid,
    data,
    mockData: {
      // callers: mock,
    },
    toggleLive,
    isLive,
    ...stage,
  }), [caller, show_guid, data, toggleLive, isLive, previewCaller, stage])

  const location = useLocation();
  const { title } = location.state || {};

  const [showSettingsTab, setShowSettingsTab] = useState(false);

  const tabs = [
    {
      id: 1,
      label: 'Board',
      content: <Board />,
      notificationCount: data?.show?.liveStage?.callers?.length,
    },
    {
      id: 2,
      label: 'Screening',
      content: <Screening />,
      notificationCount: data?.show?.preStage?.callers?.length,
    },
  ];
  const { TabMenu, TabContent } = useTabs(tabs, 'leftTabs')

  const rightTabs = [
    {
      id: 1,
      label: 'Announcements',
      content: (
        <div style={{ flex: 1, padding: 20, textAlign: 'center' }}>
          <h3>Announcements coming soon</h3>
        </div>
      ),
      notificationCount: 0,
    },
    // {
    //   id: 2,
    //   label: 'Chat',
    //   content: (
    //     <div style={{ flex: 1, padding: 20, textAlign: 'center' }}>
    //       <h3>Chat coming soon</h3>
    //     </div>
    //   ),
    //   notificationCount: 0,
    // },
  ]
  const { TabMenu: RightTabMenu, TabContent: RightTabContent } = useTabs(rightTabs, 'rightTabs')

  const onJsonCommand = useRef(debounce(async (cmd) => {
    try {
      switch (cmd.action) {
        case 'admin.join':
          console.log('WS admin join', cmd.detail);
          const newScreener = {
            guid: cmd.detail?.uid,
            name: cmd.detail?.name,
            initials: cmd.detail?.initials,
            profileImage: cmd.detail?.profile_image,
          }

          if (newScreener.guid) {
            setScreeners(previous => {
              // Check if the screener already exists based on the guid
              const screenerExists = previous.some(screener => screener.guid === newScreener.guid);

              // If the screener does not exist, add it to the list; otherwise, return the previous list
              if (!screenerExists) {
                return [...previous, newScreener];
              } else {
                return previous;
              }
            });
          }
          break;
        case 'admin.left':
          console.log('WS admin left', cmd.detail);
          setScreeners(previous => [...previous.filter(s => s.guid !== cmd.detail?.uid)])
          break;
        case 'show.start':
          console.log('WS show start');
          setHostIsLive(true);
          break;
        case 'show.stop':
          console.log('WS show stop');
          setHostIsLive(false);
          break;
        default:
          return;
      }
      console.log(cmd);
    } catch (e) {
      console.log(e)
    }
  }, 300)).current;

  const spinner = (
    <i style={{ color: 'rgba(255, 255, 255, 0.75)', fontSize: 20 }} className="fa fa-circle-o-notch fa-spin" />
  )

  return (
    <StudioContext.Provider value={contextValue}>
      <div className={styles['container']}>
        <Header>
          <div className={styles['header']}>
            <div>
              <div className={styles['header__media']}>
                <MediaControls audioOnly={!showVideo} />
              </div>
              {ROLE !== 'screener' && <TabMenu />}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 }}>
              <div style={{ display: 'flex', alignItems: 'center', position: 'relative', cursor: 'pointer', marginBottom: 5 }} onClick={() => navigate(-1)}>
                <div style={{ position: 'absolute', left: -18 }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="13.537" viewBox="0 0 8 13.537">
                    <path id="Union_105" data-name="Union 105" d="M20695.4,1446.145l-5.533-5.539a1.231,1.231,0,0,1,1.74-1.741l4.666,4.666,4.666-4.666a1.231,1.231,0,1,1,1.74,1.741l-5.533,5.539a1.233,1.233,0,0,1-1.746,0Z" transform="translate(1446.507 -20689.502) rotate(90)" fill="#fff"/>
                  </svg>
                </div>
                <div className={styles['header__title']}>{data?.show?.title ?? title}</div>
              </div>
              <button disabled={ROLE !== 'host' || loadingLive} className={styles['header__button']} style={{ color: isLive ? 'white' : '#00FF4E', alignItems: 'center' }} onClick={() => loadingLive ? null : toggleLive()}>
                <TimerDisplay isLive={hostIsLive || data?.show?.onAirAt} onAirAt={data?.show?.onAirAt}/>
                {ROLE === 'host' && (
                  loadingLive ? spinner : (
                    <div style={{ color: hostIsLive || data?.show?.onAirAt ? 'rgba(0, 0, 0, 0.75)' : 'white' }}>{hostIsLive || data?.show?.onAirAt ? 'End Show' : 'Go Live'}</div>
                  )
                )}
              </button>
            </div>
            <div className={styles['header__right']}>
              <div style={{ paddingBottom: 10 }}>
                <div style={{ display: 'flex' }}>
                  <WebSocket showGuid={show_guid} onCommand={onJsonCommand} userType={"screener"} screeners={screeners} setScreeners={setScreeners} />
                  <ProfileImg contextMenu user={user} size={50} style={{ border: '1px solid rgba(255, 255, 255, 0.2)', marginLeft: 20 }} showTooltip={false} onLogout={() => stage.leaveStage()} />
                </div>
              </div>
              <div onClick={() => {
                  setShowSettingsTab(false)
                }} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <RightTabMenu />
                <DotsOutline onClick={(e) => {
                  e.stopPropagation()
                  setShowSettingsTab(!showSettingsTab)
                }} style={{ marginLeft: 20, cursor: 'pointer' }} />
              </div>
            </div>
          </div>
        </Header>
        <div className={styles['content']}>
          {ROLE === 'screener' ? (
            <ScreenerView />
          ) : (
            <TabContent />
          )}
          <div className={styles['content__divider']} />
          <div style={{ flex: 1 }}>
            <div className={`${styles['screening-details']} ${caller && !previewCaller ? styles['screening-details-visible'] : ''}`}>
              {showCallerDetails && <ScreeningDetails caller={caller} setCaller={setCaller} setPreviewCaller={setPreviewCaller} />}
            </div>
            <div className={`${styles['screening-details']} ${previewCaller ? styles['screening-details-visible'] : ''}`}>
              {showPreviewCallerDetails && <CallerPreview caller={previewCaller} setCaller={setPreviewCaller} />}
            </div>
            <div style={{ opacity: caller || previewCaller ? 0 : 1, transition: 'opacity 0.5s ease-in-out' }}>
              {showSettingsTab ? (
                <ShowSettings show={data?.show} goBack={() => setShowSettingsTab(false)} />
              ) : (
                <RightTabContent />
              )}
            </div>
          </div>
        </div>
      </div>
    </StudioContext.Provider>
  );
}

export default function Show() {
  const { show_guid } = useParams();
  const { data, loading } = useQuery(GET_SHOW, { variables: { guid: show_guid }, pollInterval: 5000 });
  const { initializeMedia } = useContext(LocalMediaContext);

  const audioOnly = useMemo(() => data?.show?.audioOnly ?? null, [data]);

  useEffect(() => {
    if (audioOnly !== null) {
      initializeMedia(!audioOnly, true);
    }
  }, [audioOnly])

  return loading && !data ? (
    <ShowContent data={data} />
  ) : (
    <ShowContent data={data} showVideo={!(audioOnly ?? true)} />
  )
}