import React, { useContext, createContext } from 'react';
import Board from '../components/Board.js';
import Screening from '../components/Screening.js';
import styles from '../routes/Show.module.scss';

const StudioContext = createContext()
export const useStudio = () => useContext(StudioContext)

export default function Show() {
  return (
    <div className={styles['container']}>
      <Board />
      <Screening />
    </div>
  );
}
