import React from 'react';
import ProfileImg from './ProfileImg';
import { ReactComponent as MicSquare } from '../assets/mic-subtract-square.svg';
import { ReactComponent as BackArrow } from '../assets/back-circle.svg';
import { ReactComponent as Dots } from '../assets/dots-circle.svg';
import styles from './ScreeningDetails.module.scss';

export default function CallerPreview({ caller, setCaller }) {
  const goBack = async () => {
    setCaller();
  };

  return (
    <div className={styles['container']}>
      <div className={styles['container__header']}>
        <div className={styles['container__header__nav']}>
          <BackArrow style={{ cursor: 'pointer' }} onClick={goBack} />
          <ProfileImg user={caller} size={58} />
          <Dots />
        </div>
        <div className={styles['container__header__title']}>{caller?.name}</div>
      </div>
      <div className={styles['section']}>
        <div className={styles['section__header']}>
          <MicSquare fill="#FF8900" />
          <div className={styles['section__header__title']}>ON AIR</div>
        </div>
        <div className={styles['section__content']}>
          <div className={styles['section__content__text']}>{caller?.reason}</div>
        </div>
      </div>
    </div>
  );
}
