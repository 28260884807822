import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext.js';
import Background from '../components/Background.js';
import Hls from 'hls.js'
import { useQuery } from '@apollo/client';
import { GET_SHOW_CALLER } from '../util/graphql.js';

const LiveContent = ({ show }) => {
  const videoRef = useRef(null);
  const audioRef = useRef(undefined);

  const navigate = useNavigate();
  const { user } = useAuth();

  const streamUrl = process.env.REACT_APP_LIVE;
  const audioOnly = show.audioOnly ?? true;

  useEffect(() => {
    let hls;

    function setupHls(mediaElement) {
      if (Hls.isSupported()) {
        hls = new Hls();
        hls.loadSource(streamUrl);
        hls.attachMedia(mediaElement);
        hls.on(Hls.Events.MANIFEST_PARSED, function() {
          mediaElement.play().catch(error => {
            console.log("Playback failed to start without user interaction");
          });
        });
      } else if (mediaElement.canPlayType('application/vnd.apple.mpegurl')) {
        mediaElement.src = streamUrl;
        mediaElement.addEventListener('loadedmetadata', function() {
          mediaElement.play().catch(error => {
            console.log("Playback failed to start without user interaction");
          });
        });
      }
    }

    if (audioOnly && audioRef.current && streamUrl) {
      setupHls(audioRef.current);
    } else if (!audioOnly && videoRef.current && streamUrl) {
      setupHls(videoRef.current);
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [audioOnly, streamUrl]);


  return (
    <>
      <div style={{ alignItems: 'center', paddingTop: 50, textAlign: 'center' }}>
        <div style={{ fontSize: 22, fontWeight: '900', fontFamily: 'Roboto', opacity: 0.75, color: 'white' }}>{show.title ?? ' '}</div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        {audioOnly ? (
          <audio ref={audioRef} autoPlay controls={show.onAirAt} style={{
            maxWidth: 600,
            minWidth: 400,
            margin: '20px 0',
          }} />
        ) : (
          <video
            ref={videoRef}
            controls
            playsInline
            style={{
              width: '100%',
              maxWidth: 600,
              margin: '20px 0',
            }}
          />
        )}
        {show.allowCallers && (
          <button style={{
            borderRadius: 10,
            margin: 0,
            fontSize: 20,
            fontWeight: 700,
            textTransform: 'none',
            border: 0,
            backgroundColor: '#007FFF',
            padding: '5px 20px',
            lineHeight: 1.4,
          }} onClick={() => {
            if (user?.guid) {
              navigate('/caller/63e32ee0-ceeb-4315-b8a8-89ec6a788342');
            } else {
              navigate('/login?show_guid=63e32ee0-ceeb-4315-b8a8-89ec6a788342');
            }
          }}>Call In</button>
        )}
      </div>
    </>
  )
}

export default function LiveShow() {
  const { data, loading } = useQuery(GET_SHOW_CALLER, { variables: { guid: '63e32ee0-ceeb-4315-b8a8-89ec6a788342' }, pollInterval: 10000 });

  const spinner = (
    <i style={{ color: 'rgba(255, 255, 255, 0.75)', fontSize: 60 }} className="fa fa-circle-o-notch fa-spin" />
  )

  return (
    <Background>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        {loading ? (
          spinner
        ) : (
          data?.show?.onAirAt ? (
            <LiveContent show={data.show} />
          ) : (
            <div style={{ fontSize: 30, color: 'white' }}>We're currently not live</div>
          )
        )}
      </div>
    </Background>
  );
}
